import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import AppButton from './AppButton'
import Base from '../../base';


export default function ReloadView(props) {
    return (
        <View style={styles.main}>
            <Icon name="cloud-off" size={50} color={Base.colours.appGrey} />
            <Text style={styles.title}>{props.title}</Text>
            <AppButton title={'Retry'} onPress={props.onPress} style={{width:150}}/>
            <View style={{height: 70}}/>
        </View>
	)
}


const styles = StyleSheet.create({
    main: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },

    title: {
        margin: 35,
        fontSize: 16,
        textAlign: 'center',
        color: 'black'
    }
})