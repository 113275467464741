import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { ExpandableButton } from '../../common'
import Collapsible from 'react-native-collapsible';
import Base, { Firebase } from '../../../base'
import OrderDetailsItem from './OrderDetailsItem'


export default function OrderDetailsGuestView(props) {
	const [open, setOpen] = useState(false);

	function callCustomer() {
		Base.functions.callNumber(props.order.customer.phoneNumber)
		Firebase.logEvent('order_called');
	}

	return (
		<Collapsible
			collapsed={!open}
			collapsedHeight={48}
			enablePointerEvents={true}>
			<ExpandableButton 
				style={styles.button} 
				titleStyle={styles.buttonTitle}
				title={'Guest Details'} 
				onPress={() => setOpen(!open)} 
				isOpen={open} />
			<View style={{ height: 10 }} />
			<View style={styles.contentRow}>
				<OrderDetailsItem
					title='Name'
					value={props.order.customer.firstName} />
				<OrderDetailsItem
					title='Customer Number'
					value={props.order.customer.phoneNumber}
					onPress={callCustomer}
					style={{ textDecorationLine: 'underline' }} />
			</View>
			<View style={styles.contentRow}>
				<OrderDetailsItem
					title='Email'
					mainStyle={{width: '100%'}}
					value={props.order.customer.email} />
			</View>	
			<View style={{ height: 20 }} />
		</Collapsible>
	);
}


const styles = StyleSheet.create({
	main: {
		width: '47%',
	},

	button: {
		borderTopColor: Base.colours.appSemiLightGrey,
		borderTopWidth: 1 
	},

	buttonTitle: {
		fontFamily: Base.fonts.appSemiBold,
		fontSize: 16,
		color: 'black',
	},

	value: {
		fontFamily: Base.fonts.appBold,
		color: Base.colours.appDarkBlue,
		marginTop: 4,
		fontSize: 19,
	},

	contentRow: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 16,
	},
})