import { Alert, Platform } from 'react-native'

function show(title, message, button = 'OK', onPress = null) {
    if (Platform.OS === 'web') {
        alert(`${title}\n${message}`)
        if (onPress !== null) onPress()
        return
    }
    Alert.alert(
        title, 
        `\n` + message, 
        [{text: button, onPress}], 
        {cancelable: false})
}
  

function showTwoButton(title, message, positiveButton, negativeButton) {
    if (Platform.OS === 'web') {
        const res = window.confirm(`${title}\n${message}`)
        if (res) positiveButton.onPress()
        else negativeButton.onPress()
        return
    }
    Alert.alert(
        title, 
        message, 
        [positiveButton, negativeButton], 
        {cancelable: false})
}

export default {show, showTwoButton}
