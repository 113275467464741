import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import * as Animatable from 'react-native-animatable';
import Base from '../../base'


export default function ExpandableButton(props) {

	function angle() {
		if (props.static) { return '270deg' }
		return (props.isOpen) ? '180deg' : '0deg'
	}

	return (
		<TouchableOpacity style={[styles.main, props.style]} onPress={() => props.onPress(props.index)}>
			<Text style={[styles.title, props.titleStyle]}>{props.title}</Text>
			<Animatable.View transition='rotate' style={{ rotate: angle() }}>
				<Icon name="keyboard-arrow-down" size={25} color={Base.colours.appRed} />
			</Animatable.View>
		</TouchableOpacity>
	);
}


const styles = StyleSheet.create({
	main: {
		flexDirection: 'row',
		height: 48,
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottomColor: Base.colours.appSemiLightGrey,
		borderBottomWidth: 1
	},

	title: {
		marginEnd: 10,
		fontSize: 16,
		color: Base.colours.appDarkBlue,
		fontFamily: Base.fonts.appExtraBold
	},
})