import React from "react";
import { View, StyleSheet } from "react-native";
import Base from "../../base";
import SettingsHeaderButton from "./SettingsHeaderButtonV2";

export default function SettingsHeader(props) {
  if (!props.service) {
    return <View />;
  }
  return (
    <View style={styles.main}>
      <View style={props.isMobile ? styles.categories : styles.categories_rg}>
        {props.services.map((service, index) => {
          let selected = props.service.shortcode === service.shortcode;
          return (
            <SettingsHeaderButton
              selected={selected}
              onChanged={props.onServiceChanged}
              service={service}
              key={index}
              isMobile={props.isMobile}
            />
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: "white",
    alignItems: "center",
  },

  categories: {
    height: 48,
    flexDirection: "row",
  },

  categories_rg: {
    minWidth: 173,
    maxWidth: 173,
    flex: 1,
    borderColor: Base.colours.appSemiLightGrey,
    borderRightWidth: 2.0,
  },

  versionNumber: {
    alignSelf: "center",
    marginVertical: 10,
  },
});
