import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/AntDesign';
import Base, { Api, Actions } from '../../../base';
import { Alert, AppButton } from '../../common';
import { Picker } from '@react-native-community/picker';

function header(navigation, time, venue) {
	return {
		...Base.constants.headerOptions(false, 'Wait Time'),
		headerLeft: () => (
			<TouchableOpacity style={Base.styles.headerButton} onPress={() => {
				console.log(time)
				console.log(venue.averageWaitTime)
				if (time > 0 && time != Number(venue.averageWaitTime)) {
					Alert.showTwoButton(
						'Hold Up!',
						`You've not saved your new wait time. Discard and return to settings?`,
						{ text: 'Discard Changes', onPress: () => navigation.goBack() },
						{ text: 'Continue Edit', onPress: () => { } })
				} else {
					navigation.goBack()
				}
			}}>
				<Icon name='arrowleft' size={24} color='white' />
			</TouchableOpacity>
		),
		headerTitleStyle: {
			fontFamily: Base.fonts.appExtraBold,
			fontSize: 18,
			fontWeight: '800',
		},
	}
}

export default function WaitTimeScreen(props) {
	const times = []
	for (var i = 0; i < 46; i++) { times.push(i) }
	const isMobile = useMediaQuery(Base.constants.mobile);
	const [loading, setLoading] = useState(false);
	const venue = useSelector(state => state.venue);
	const [visible, setVisible] = useState(Platform.OS === 'ios');
	const [time, setTime] = useState(-1);
	const dispatch = useDispatch();
	props.navigation.setOptions(header(props.navigation, time, venue))

	async function onSave() {
		// if (time < 1) {
		// 	Alert.show('Hold Up!', 'Please enter a valid wait time before saving.');
		// 	return;
		// }
		setLoading(true);
		const averageWaitTime = time;
		const oldWaitTime = venue.oat.averageWaitTime;
		const newVenue = { ...venue, oat: { ...venue.oat, averageWaitTime } };
		dispatch(Actions.setVenue(newVenue));
		const success = await Api.saveWaitTime(averageWaitTime);
		if (!success || success === null) {
			Alert.show('Hold Up!', 'Unable to update this service at the moment, please check your connection and try again.');
			newVenue.oat.averageWaitTime = oldWaitTime;
			dispatch(Actions.setVenue(newVenue));
		} else {
			Alert.show(
				'Success!',
				'The wait time for your venue was updated successfully.',
				'OK',
				() => props.navigation.goBack());
		}
		setLoading(false);
	}

	return (
		<View style={styles.main}>
			<View style={[styles.content, !isMobile && { marginTop: 50 }]}>
				<Text style={[Base.styles.body, {textAlign: 'center'}]}>
					{`You can set the time for the message that is displayed to a customer.\nIf set to '0' the message will not be displayed.`}
				</Text>
				<Text style={styles.subtitle}>
					{`"We're a little busier than usual, your order will be ready`}
				</Text>
				<TouchableOpacity style={styles.textfield} activeOpacity={1} onPress={() => setVisible(!visible)}>
				<Text style={styles.timeSuffix}>{`within `}</Text>
					<View style={styles.timeInput}>
						{Platform.OS == 'ios' ?
							<Text style={styles.timeInputText}>{time < 0 ? venue.oat.averageWaitTime : time}</Text>
							:
							<Picker
								selectedValue={time < 0 ? venue.oat.averageWaitTime : time}
								style={styles.pickerAndroid}
								prompt='Select a time'
								onValueChange={(value) => setTime(value)}>
								{times.map(x => <Picker.Item key={x} label={`${x}`} value={x} />)}
							</Picker>}
					</View>
					<Text style={styles.timeSuffix}>{`minutes"`}</Text>
				</TouchableOpacity>
				<AppButton style={styles.saveButton} title='Save' onPress={onSave} loading={loading} />
			</View>
			{visible && Platform.OS === 'ios' && <Picker
				selectedValue={time < 0 ? venue.oat.averageWaitTime : time}
				style={styles.pickeriOS}
				onValueChange={(value) => setTime(value)}>
				{times.map(x => <Picker.Item key={x} label={`${x}`} value={x} />)}
			</Picker>}

		</View>
	)
}


const styles = StyleSheet.create({
	main: {
		flex: 1,
		backgroundColor: Base.colours.appLightGrey,
		alignItems: 'center'
	},

	content: {
		maxWidth: 600,
		marginHorizontal: 16,
		marginTop: 25
	},

	subtitle: {
		marginTop: 35,
		fontFamily: Base.fonts.appSemiBold,
		fontSize: 16,
		textAlign: 'center'
	},

	textfield: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 8,
		marginBottom: 25,
	},

	timeInput: {
		backgroundColor: 'white',
		width: 90,
		height: 44,
		marginHorizontal: 6,
		borderRadius: 20,
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: Base.colours.appLightGrey
	},

	timeInputText: {
		textAlign: 'center',
		fontFamily: Base.fonts.appSemiBold,
		fontSize: 16
	},

	timeSuffix: {
		fontFamily: Base.fonts.appSemiBold,
		fontSize: 16,
		marginLeft: 6,
	},

	saveButton: {
		alignSelf: 'center',
		width: 200,
		height: 44,
		marginTop: 40,
	},

	pickeriOS: {
		height: '40%',
		width: '100%',
		position: 'absolute',
		bottom: 0,
		backgroundColor: 'white'
	},

	pickerAndroid: {
		marginLeft: 10,
		marginRight: 10,
	}
})