import Auth0 from "react-native-auth0";
import Sentry from "../sentry";
import Firebase from "../firebase";
import Store from "../store";
import Actions from "../store/Actions";

var jwtDecode = require("jwt-decode");

function getClient() {
  const domain = Firebase.getConfigValue("auth0_domain");
  const clientId = Firebase.getConfigValue("auth0_clientid");
  return new Auth0({ domain, clientId });
}

function tokenExpired(creds) {
  const now = Math.floor(Date.now() / 1000);
  const issueDate = creds.dateIssued || 0;
  return now - issueDate > creds.expiresIn;
}

async function getToken() {
  let creds = Store.getState().credentials;
  if (tokenExpired(creds)) {
    const newCreds = await refreshToken(creds);
    if (newCreds === null || typeof newCreds === "undefined") {
      console.log("TOKEN EXPIRED AND REFRESH TOKEN NULL, LOGGING OUT: ", creds);
      Sentry.addBreadcrumb({ data: creds, message: "Previous credentials" });
      Sentry.logMessage("Unable to refresh Auth0 token, logging user out.");
      return null;
    }
    Store.dispatch(Actions.setCredentials(newCreds));
    return newCreds.accessToken;
  }
  return creds.accessToken;
}

async function refreshToken(creds) {
  if (!creds.refreshToken) return null;

  console.log(
    "REFRESHING CREDENTIALS: ",
    Date.now() / 1000 - creds.dateIssued,
    creds.expiresIn
  );
  return getClient()
    .auth.refreshToken({ refreshToken: creds.refreshToken })
    .then((response) => {
      return decodeCredentials(response, creds);
    })
    .catch((error) => {
      console.log("REFRESH ERROR: ", error);
      Sentry.logError(error);
      return null;
    });
}

async function login(username, password) {
  let scope = Firebase.getConfigValue("auth0_scope");
  let audience = Firebase.getConfigValue("auth0_audience");
  let realm = Firebase.getConfigValue("auth0_connection");

  return getClient()
    .auth.passwordRealm({ scope, audience, username, password, realm })
    .then((response) => {
      return decodeCredentials(response);
    })
    .catch((error) => {
      Sentry.logError(error);
      console.log(error);
      return null;
    });
}

async function forgotPassword(email) {
  let connection = Firebase.getConfigValue("auth0_connection");

  return getClient()
    .auth.resetPassword({ email, connection })
    .then((response) => {
      console.log("PASSWORD RESET: ", response);
      return true;
    })
    .catch((error) => {
      Sentry.logError(error);
      console.log("PASSWORD RESET ERROR: ", error);
      return false;
    });
}

function decodeCredentials(response, creds = {}) {
  try {
    const credentials = {
      ...response,
      dateIssued: Math.floor(Date.now() / 1000),
      userVersion: 1,
    };
    if (!credentials.refreshToken)
      credentials.refreshToken = creds.refreshToken;
    if (credentials.accessToken) {
      const venueKey = Firebase.getConfigValue("auth0_venue_key");
      const auth0Url = Firebase.getConfigValue("auth0_url");
      const whitelabelApi = Firebase.getConfigValue("api_url");
      const permissionsUrl = `${whitelabelApi}/permissions`;
      const rolesUrl = `${auth0Url}/roles`;
      const jwt = jwtDecode(credentials.accessToken);
      if (!jwt?.[venueKey]?.venueRef) {
        credentials.userVersion = 2;
      }
      if (jwt?.[venueKey]) {
        const venueRef = jwt[venueKey].venueRef;
        credentials.venueId = venueRef;
      }
      if (jwt?.[permissionsUrl] && jwt?.[permissionsUrl].length) {
        credentials.permissions = jwt?.[permissionsUrl];
      }
      if (jwt?.[rolesUrl] && jwt?.[rolesUrl].length) {
        credentials.roles = jwt?.[rolesUrl];
      }
    }
    return credentials;
  } catch (error) {
    console.log(error);
  }
}

function logout() {}

export default {
  login,
  logout,
  refreshToken,
  forgotPassword,
  tokenExpired,
  getToken,
};
