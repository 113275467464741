import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Base from '../../base';
import { useSelector } from 'react-redux';


export default function LiveIndicator() {
	const isConnected = useSelector(state => state.connected);

	return (
	<View style={styles.main}>
		<Icon name='circle' size={13} color={(isConnected)? Base.colours.appGreen : Base.colours.appRed} />
		<Text style={styles.title}>{(isConnected)? 'Connected' : 'Disconnected'}</Text>
	</View>	)
}


const styles = StyleSheet.create({
	main: {
		flexDirection: 'row',
		flex: 1,
		alignItems: 'center',
		marginLeft: Base.constants.hmargin.sm,
	},

	title: {
		alignSelf: 'center',
		fontSize: 13,
		marginBottom:1,
		color: 'white',
		
		marginLeft: 4,
		fontFamily: Base.fonts.appSemiBold,
	},
})