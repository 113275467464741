import React from "react";
import { View, Text, StyleSheet, Switch } from "react-native";
import Base from "../../base";

export default function SettingsSwitch(props) {
  return (
    <View style={[styles.main, !props.isMobile && { height: 65 }]}>
      <View style={styles.content}>
        <Text style={styles.title}>{props.title}</Text>
        <Switch
          disabled={props.disabled}
          style={Base.styles.switch}
          value={props.enabled}
          onValueChange={props.onValueChanged}
          ios_backgroundColor={Base.colours.appNewDarkGrey}
          thumbColor={"white"}
          trackColor={{
            false: Base.colours.appNewDarkGrey,
            true: Base.colours.appGreen,
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 56,
  },

  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Base.colours.appWhite,
    alignItems: "center",
    width: "100%",
    height: 48,
    paddingHorizontal: 16,
    borderRadius: 54,
  },

  title: {
    marginEnd: 10,
    fontSize: 16,
    color: Base.colours.appDarkBlue,
    fontFamily: Base.fonts.appExtraBold,
    maxWidth: "80%",
  },
});
