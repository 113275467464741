import React, { useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import LoginScreen from "./views/login/LoginScreen";
import OrdersScreen from "./views/orders/OrdersScreen";
import Base, { Firebase, Sentry, Api, Actions } from "./base";
import VenueSelectScreen from "./views/venue/VenueSelectScreen";

export default function RootScreen(props) {
  const { navigation } = props;
  const dispatch = useDispatch();
  const [configFetched, setConfigFetched] = useState(false);
  const credentials = useSelector((state) => state.credentials);
  const hasVenue = credentials.venueId;
  useEffect(() => {
    fetchConfig();
  }, []);
  async function fetchConfig() {
    let fetched = await Firebase.init();
    if (credentials.userVersion === 2) {
      const me = await Api.getMe();
      dispatch(Actions.setMe(me));
    }
    setConfigFetched(fetched);
    Sentry.init();
  }

  if (!configFetched) {
    navigation.setOptions(Base.constants.headerOptions(true));
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <ActivityIndicator size="large" color={Base.colours.appGrey} />
      </View>
    );
  }

  if (
    credentials.idToken === null ||
    typeof credentials.idToken === "undefined"
  ) {
    return <LoginScreen navigation={navigation} />;
  } else if (hasVenue) {
    return <OrdersScreen navigation={navigation} />;
  } else {
    return (
      <VenueSelectScreen
        navigation={navigation}
        fetchConfig={fetchConfig}
        isV2User={credentials.userVersion === 2}
      />
    );
  }
}
