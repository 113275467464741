import React from "react";
import { View, Text, StyleSheet } from "react-native";
import Base from "../../../base";

export default function OrderDetailsItemRow(props) {
  const itemTotal = Number(props.item.total.$numberDecimal ?? "0");
  const price = props.formatter?.format(props.item.quantity * itemTotal);

  return (
    <View style={styles.main}>
      <View style={styles.item}>
        {!props.chef && (
          <Text style={styles.quantity}>{props.item.quantity}x</Text>
        )}
        <Text style={styles.value}>
          <Text style={styles.itemName}>{props.item.name}</Text>
        </Text>
        {props.chef && (
          <Text style={styles.quantity}>x{props.item.quantity}</Text>
        )}
        <Text style={styles.price}>{price}</Text>
      </View>
      {props.item.choices.map((choice, i) => {
        const itemTotal = Number(choice.price.$numberDecimal ?? "0");
        const choicePrice =
          itemTotal > 0 && props.formatter
            ? props.formatter.format(itemTotal)
            : "";
        return (
          <View style={styles.choice} key={i}>
            <Text style={styles.value}>
              <Text style={styles.itemName}>- {choice.name}</Text>
            </Text>
            {props.formatter && <Text style={styles.price}>{choicePrice}</Text>}
          </View>
        );
      })}
      {props.item?.messages && props.item?.messages?.length ? (
        <Text style={styles.message}>
          Notes: {props.item.messages.join(", ")}
        </Text>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: Base.colours.appSemiLightGrey,
  },

  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  choice: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 40,
    marginVertical: 8,
  },

  quantity: {
    fontFamily: Base.fonts.appExtraBold,
    fontSize: 17,
    color: Base.colours.appDarkBlue,
  },

  itemName: {
    fontFamily: Base.fonts.appSemiBold,
    fontSize: 17,
  },

  sku: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 17,
    color: Base.colours.appGrey,
  },

  value: {
    flexDirection: "row",
    flex: 1,
    marginHorizontal: 12,
    fontFamily: Base.fonts.appSemiBold,
  },

  price: {
    fontFamily: Base.fonts.appRegular,
    color: Base.colours.appBlack,
    fontSize: 16,
  },
  message: {
    color: Base.colours.appBlue,
  },
});
