import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import Icon2 from "react-native-vector-icons/AntDesign";
import { Overlay } from "react-native-elements";
import Modal from "modal-react-native-web";
import Base, { Api } from "../../../base";
import moment from "moment";
import Dash from "react-native-dash";
import OrderDetailItemRow from "./OrderDetailItemRow";
import OrderDetailsItem from "./OrderDetailsItem";
import OrderDetailsGuestView from "./OrderDetailsGuestView";
import { AppButton } from "../../common";
import { useSelector, useDispatch } from "react-redux";

export default function OrderDetailsView(props) {
  const { modalName, setModalName } = props;
  const [refundReason, setRefundReason] = useState("");
  // const [modalName, setModalName] = useState("main");
  const [apiState, setApiState] = useState("initial");
  const [isCancelled, setIsCancelled] = useState(false);
  const me = useSelector((state) => state.me);
  const credentials = useSelector((state) => state.credentials);
  const permissions = credentials?.permissions;
  const venueId = credentials?.venueId;
  const refundReasons = me?.business?.orderCancellationReasons;
  const canRefund =
    permissions?.includes("update:order-status") &&
    refundReasons?.length &&
    isCancelled === false &&
    props?.order?.status !== "completed" &&
    props.order?.status !== "cancelled";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  });
  const rawTotal = Number(props.order?.total?.$numberDecimal ?? "0");
  const totalCost = formatter.format(rawTotal);
  const handleRefund = () => {
    setModalName("refundReason");
  };
  const handleOnShow = () => {
    console.log("showing modal");
    if (props?.order?.status === "cancelled") {
      setIsCancelled(true);
    } else {
      setIsCancelled(false);
    }
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const handleSendRefund = async ({ orderId, venueId }) => {
    try {
      const status = "cancelled";
      const reason = refundReason;
      setApiState("loading");
      const res = await Api.setOrderStatus({
        orderId,
        venueId,
        status,
        reason,
      });

      if (res && res.status === status) {
        await props.getOrders();
        setIsCancelled(true);
        setModalName("main");
        setApiState("initial");
      } else {
        throw new Error("API rejection");
      }
    } catch (error) {
      await setApiState("error");
      console.error("An error occurred sending the refund", error);
      await sleep(1000);
      setApiState("initial");
    }
  };
  function cardStyle() {
    if (Platform.OS !== "web") {
      return styles.card_full;
    }
    return props.isMobile ? styles.card_sm : styles.card_rg;
  }
  function Main() {
    return (
      <View style={styles.main}>
        <View style={[cardStyle(), styles.card]}>
          <View style={styles.header}>
            <Text style={styles.headerTitle}>Order Details</Text>
            <TouchableOpacity
              style={styles.headerButton}
              onPress={props.onDismiss}
            >
              <Icon name="close" size={24} color={Base.colours.appDarkBlue} />
            </TouchableOpacity>
          </View>
          {!props.order?.basket ? (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <ActivityIndicator size="large" color={Base.colours.appGrey} />
            </View>
          ) : (
            <View style={{ flex: 1, paddingBottom: 64 }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.content}>
                  {props.order && props.order.type === "poat" ? (
                    <View style={styles.contentRow}>
                      <OrderDetailsItem
                        title="Deliver at"
                        value={
                          props.order?.timeslot
                            ? moment(props.order?.timeslot).format("HH:mm")
                            : "N/A"
                        }
                        style={{ color: Base.colours.appGreen }}
                      />
                      <OrderDetailsItem
                        title="Customer name"
                        value={props.order?.customer?.firstName || "N/A"}
                        style={{ color: Base.colours.appGreen }}
                      />
                    </View>
                  ) : null}
                  <View style={styles.contentRow}>
                    <OrderDetailsItem
                      title={
                        props.order?.timeslot && props.order?.type !== "poat"
                          ? "Collecting At"
                          : "Table Number"
                      }
                      value={
                        props.order?.timeslot && props.order?.type !== "poat"
                          ? moment(props.order?.timeslot).format("HH:mm")
                          : `#${props.order?.destination}`
                      }
                      style={{ color: Base.colours.appGreen }}
                    />
                    <OrderDetailsItem
                      title="Order Number"
                      value={"#" + props.order?.reference}
                    />
                  </View>
                  <View style={styles.contentRow}>
                    <OrderDetailsItem
                      title="Order Submitted"
                      value={moment(props.order?.orderedAt).format("HH:mm")}
                    />
                    <OrderDetailsItem
                      title="Status"
                      value={isCancelled ? "Cancelled" : props.order?.status}
                    />
                  </View>
                  <OrderDetailsItem
                    title="Customer Notes"
                    value={props.order?.notes}
                    mainStyle={{ width: "100%" }}
                    style={{ color: Base.colours.appRed, marginVertical: 10 }}
                  />
                  <OrderDetailsGuestView order={props?.order} />
                  <View style={{ height: 20 }} />
                  <View style={styles.priceRow}>
                    <Text style={styles.itemCount}>
                      {props.order?.quantity}{" "}
                      {props.order?.quantity === 1 ? "item" : "items"}
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[Base.styles.body, { marginRight: 9 }]}>
                        Total
                      </Text>
                      <Text style={styles.itemCount}>{totalCost}</Text>
                    </View>
                  </View>
                  {props.order?.promotions &&
                    props.order?.promotions.map((promo, i) => {
                      return (
                        <View style={{ marginBottom: 24 }}>
                          <View style={styles.priceRow} key={i}>
                            <Text
                              style={[
                                styles.itemCount,
                                { color: Base.colours.appDarkBlue },
                              ]}
                            >
                              {"Promo: " + promo.internalName}
                            </Text>
                            <Text style={Base.styles.body}>
                              {formatter.format(promo.discount ?? "0.0")}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  {props.order?.basket.map((item, index) => {
                    return (
                      <OrderDetailItemRow
                        key={index}
                        item={item}
                        formatter={formatter}
                      />
                    );
                  })}
                </View>
                <View style={{ height: 24 }} />
              </ScrollView>
              {canRefund ? (
                <View style={styles.refundButtonWrapper}>
                  <AppButton
                    title="Refund"
                    onPress={handleRefund}
                    style={styles.refundButton}
                  />
                </View>
              ) : null}
            </View>
          )}
        </View>
      </View>
    );
  }
  function RefundReasonModal() {
    return (
      <View style={styles.main}>
        <View style={[cardStyle(), styles.card]}>
          <View style={styles.header}>
            <TouchableOpacity style={styles.headerButton}>
              <Icon2
                name="arrowleft"
                size={24}
                color={Base.colours.appDarkBlue}
                onPress={() => setModalName("main")}
              />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Select Refund Reason</Text>
            <TouchableOpacity
              style={styles.headerButton}
              onPress={props.onDismiss}
            >
              <Icon name="close" size={24} color={Base.colours.appDarkBlue} />
            </TouchableOpacity>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.content}>
              {refundReasons.length &&
                refundReasons.map((reason) => {
                  return (
                    <TouchableOpacity
                      onPress={() => setRefundReason(reason)}
                      style={styles.reason}
                    >
                      <Text
                        style={
                          reason === refundReason && { fontWeight: "bold" }
                        }
                      >
                        {reason}
                      </Text>
                      {reason === refundReason && (
                        <Icon
                          name="done"
                          size={22}
                          color={Base.colours.appRed}
                        />
                      )}
                    </TouchableOpacity>
                  );
                })}
            </View>
          </ScrollView>
          {canRefund && refundReason ? (
            <View style={styles.refundButtonWrapper}>
              <AppButton
                title={apiState === "error" ? "Error occurred" : "Send"}
                onPress={() =>
                  handleSendRefund({ venueId, orderId: props.order?._id })
                }
                style={styles.refundButton}
                loading={apiState === "loading"}
                disabled={
                  !refundReason ||
                  apiState === "loading" ||
                  apiState === "error"
                }
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  }
  if (Platform.OS === "web") {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={props.visible}
        onDismiss={props.onDismiss}
        onShow={() => handleOnShow()}
        ariaHideApp={false}
      >
        {modalName === "refundReason" ? <RefundReasonModal /> : <Main />}
      </Modal>
    );
  } else {
    return (
      <Overlay
        isVisible={props.visible}
        overlayStyle={{
          padding: 0,
          paddingTop: 10,
          shadowOpacity: 0,
          backgroundColor: "transparent",
        }}
        borderRadius={4}
        width={!props.isMobile ? 600 : "90%"}
        height={"90%"}
        windowBackgroundColor="#000000dd"
        animationType={"fade"}
        statusBarTranslucent
        onBackdropPress={props.onDismiss}
        onShow={() => handleOnShow()}
      >
        {modalName === "refundReason" ? <RefundReasonModal /> : <Main />}
      </Overlay>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: "#00000097",
    alignItems: "center",
    justifyContent: "center",
  },

  card: {
    backgroundColor: "white",
    marginRight: -1,
    borderRadius: 4,
    overflow: "hidden",
    justifyContent: "space-between",
  },

  card_sm: {
    width: "90%",
    height: "90%",
  },

  card_rg: {
    width: 600,
    height: "90%",
  },

  card_full: {
    width: "100%",
    height: "97%",
    minWidth: "96%",
  },

  header: {
    height: 64,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Base.colours.appYellow,
    alignItems: "center",
    paddingHorizontal: 16,
  },

  headerTitle: {
    color: Base.colours.appDarkBlue,
    fontFamily: Base.fonts.appExtraBold,
    fontSize: 24,
  },

  content: {
    marginHorizontal: 16,
    marginTop: 16,
  },

  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },

  dashedLine: {
    height: 1,
    overflow: "hidden",
  },

  priceRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 12,
  },

  itemCount: {
    color: Base.colours.appGreen,
    fontFamily: Base.fonts.appExtraBold,
    fontSize: 16,
  },

  footer: {
    backgroundColor: Base.colours.appDarkBlue,
    height: 56,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  actionButton: {
    width: 130,
    height: 36,
  },

  completedActionButton: {
    width: 190,
    height: 36,
    backgroundColor: Base.colours.appGrey,
  },
  refundButtonWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    borderRadius: 0,
    backgroundColor: Base.colours.appDarkBlue,
    paddingVertical: 8,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
  },
  refundButton: {
    width: 200,
  },
  reason: {
    fontSize: 16,
    borderBottomColor: Base.colours.appLightGrey,
    borderBottomWidth: 2,
    paddingVertical: 8,
    color: Base.colours.appDarkBlue,
    fontWeight: "600",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    flexDirection: "row",
    minHeight: 40,
  },
});
