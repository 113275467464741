import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Text,
  Button,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import Modal from "modal-react-native-web";
import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/AntDesign";
import Icon2 from "react-native-vector-icons/Feather";
import Base, { Api, Actions, Firebase } from "../../base";
import { Alert, LoadingView, ExpandableButton } from "../common";
import SettingsHeader from "./SettingsHeaderV2";
import SettingsSwitch from "./SettingsSwitchV2";
var jwtDecode = require("jwt-decode");

function settingsHeader(navigation, logout, isMobile, user) {
  let username;
  if (user?.idToken) {
    const jwt = jwtDecode(user?.idToken ?? "");
    username = jwt["email"]?.length > 0 ? jwt["email"] : jwt["nickname"];
  }
  return {
    ...Base.constants.headerOptions(false, "Settings"),
    headerLeft: () => (
      <TouchableOpacity
        style={Base.styles.headerButton}
        onPress={() => navigation.goBack()}
      >
        <Icon name="arrowleft" size={24} color="white" />
      </TouchableOpacity>
    ),
    headerRight: () => (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!isMobile && username && (
          <Text style={styles.username}>{username}</Text>
        )}
        <TouchableOpacity style={Base.styles.headerButton} onPress={logout}>
          <Icon2 name="log-out" size={24} color="white" />
        </TouchableOpacity>
      </View>
    ),
  };
}

export default function SettingsScreen(props) {
  const services = [
    {
      label: "Takeaway",
      shortcode: "CC",
      menuName: "takeaway",
      apisAvailable: {
        toggleService: true,
        endTime: true,
        waitTime: true,
        pauseMenu: true,
      },
    },
    {
      label: "Order at Table",
      shortcode: "OAT",
      menuName: "oat",
      apisAvailable: {
        toggleService: true,
        endTime: false,
        waitTime: true,
        pauseMenu: true,
      },
    },
    {
      label: "Pre-Order At Table",
      shortcode: "POAT",
      menuName: "poat",
      apisAvailable: {
        toggleService: true,
        endTime: true,
        waitTime: true,
        pauseMenu: true,
      },
    },
  ];
  const [service, setService] = useState(services[0]);
  const [loading, setLoading] = useState(null);
  const isMobile = useMediaQuery(Base.constants.mobile);
  const user = useSelector((state) => state.credentials);
  const venue = useSelector((state) => state.venue);
  const venueName = user.venueId;
  const endTime = venue?.orderingUntil?.activeUntil;
  const endTimeText = endTime
    ? moment(endTime).format("Do MMM, HH:mm")
    : "Not Set";
  const currentServiceState =
    venue?.orderingMods?.[`enable${service.shortcode}`];
  const dispatch = useDispatch();
  const permissions = user?.permissions;
  const hasPermissions = permissions?.length;
  const roles = user?.roles;
  async function logout() {
    await Base.functions.logoutUser(() => {
      Firebase.logEvent("logout_settings");
      props.navigation.push("Root");
    });
  }

  const apisAvailable = Object.values(service.apisAvailable).reduce(
    (acc, val) => {
      if (val) {
        acc = true;
      }
      return acc;
    }
  );
  const settingsAvailable = apisAvailable && hasPermissions;
  props.navigation.setOptions(
    settingsHeader(props.navigation, logout, isMobile, user)
  );
  useEffect(() => {
    if (!user) {
      props.navigation.push("Root");
    }
  }, [user]);
  useEffect(() => {
    Api.getMe();
  }, []);

  async function menuToggled(key) {
    let enabled = menuPaused(key);

    setLoading("Updating Service...");
    let success = false;
    if (!enabled) {
      dispatch(Actions.pauseMenu(key));
      success = await Api.pauseMenu(key);
    } else {
      dispatch(Actions.unPauseMenu(key));
      success = await Api.unPauseMenu(key);
    }

    if (!success) {
      Alert.show(
        "Hold Up!",
        "Unable to update this service at the moment, please check your connection and try again."
      );
      dispatch(enabled ? Actions.pauseMenu(key) : Actions.unPauseMenu(key));
    }
    setLoading(null);
  }

  function menuPaused(key) {
    const pausedDate = venue?.oat?.menus?.[key]?.pausedUntil;
    if (!pausedDate) return false;
    return moment(pausedDate).isAfter(moment().utc());
  }
  const setServiceState = async ({ state, service }) => {
    try {
      await setLoading("Updating service");
      const apiResponse = await Api.setVenueServiceState({ service, state });
      if (apiResponse) {
        await dispatch(Actions.setServicesState(apiResponse));
        setLoading(null);
      } else {
        await setLoading(null);
        throw new Error("API response invalid");
      }
    } catch (error) {
      console.error("An error occurred setting service state", error);
    }
  };
  return (
    <View style={styles.main}>
      <TouchableOpacity onPress={() => props.navigation.push("VenueSelect")}>
        <View style={styles.banner}>
          <Text style={styles.bannerText}>{venueName}</Text>
        </View>
      </TouchableOpacity>
      <View style={{ flex: 1, flexDirection: !isMobile ? "row" : "column" }}>
        <SettingsHeader
          isMobile={isMobile}
          services={services}
          service={service}
          onServiceChanged={setService}
        />
        {settingsAvailable ? (
          <View style={styles.content}>
            <View
              style={{ maxWidth: 700, width: "100%", paddingHorizontal: 30 }}
            >
              {permissions?.includes("edit:enabled-services") &&
                service.apisAvailable.toggleService && (
                  <SettingsSwitch
                    style={styles.newSwitch}
                    key="enableService"
                    isMobile={isMobile}
                    title={`Enable ${service.label}`}
                    enabled={currentServiceState}
                    onValueChanged={(e) =>
                      setServiceState({ service, state: e })
                    }
                    disabled={loading}
                  />
                )}
              <View style={styles.groupedSwitches}>
                {service.apisAvailable.pauseMenu &&
                  venue.menus &&
                  venue.menus[service.menuName] &&
                  venue.menus[service.menuName].map((menu, i) => {
                    return (
                      <SettingsSwitch
                        key={i}
                        isMobile={isMobile}
                        title={`Pause ${menu.title} Menu for 30 mins`}
                        enabled={menuPaused(menu.identifier)}
                        onValueChanged={() => menuToggled(menu.identifier)}
                      />
                    );
                  })}
              </View>
              {service.apisAvailable.waitTime && (
                <ExpandableButton
                  style={styles.expandableButton}
                  title="Wait Time Message"
                  onPress={() => props.navigation.push("WaitTime")}
                  static={true}
                />
              )}
              {permissions?.includes("edit:venue-ordering-until") &&
                service.apisAvailable.endTime && (
                  <View>
                    <ExpandableButton
                      style={styles.expandableButton}
                      title={`End Time: ${endTimeText}`}
                      onPress={() => props.navigation.push("EndTime")}
                      static={true}
                    />
                  </View>
                )}
            </View>
          </View>
        ) : (
          <View style={styles.comingSoon}>
            <Text
              style={styles.comingSoonText}
            >{`${service.label} currently has no configurable settings, check back soon.`}</Text>
          </View>
        )}
      </View>
      {Platform.OS === "web" ? (
        <Modal
          animationType="fade"
          transparent={true}
          visible={!!loading}
          ariaHideApp={false}
        >
          <LoadingView title={loading} />
        </Modal>
      ) : (
        <Overlay
          isVisible={!!loading}
          windowBackgroundColor="#00000097"
          overlayStyle={{
            backgroundColor: "transparent",
            shadowOpacity: 0,
            elevation: 0,
          }}
          animationType={"fade"}
          statusBarTranslucent
        >
          <LoadingView title={loading} />
        </Overlay>
      )}
      {isMobile ? (
        <View style={styles.footer}>
          <Text style={styles.versionNumber}>
            {Base.functions.versionNumber()}
          </Text>
        </View>
      ) : (
        <Text style={styles.tabletVersionNumber}>
          {Base.functions.versionNumber()}
        </Text>
      )}
    </View>
  );
}
const defaultHorizontalMargin = 30;
const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: Base.colours.appLightGrey,
  },

  content: {
    flex: 1,
    marginTop: 30,
    alignItems: "center",
  },

  footer: {
    paddingTop: 12,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },

  versionNumber: {
    textAlign: "center",
    fontSize: 12,
    color: Base.colours.appGrey,
  },

  tabletVersionNumber: {
    fontSize: 12,
    color: Base.colours.appGrey,
    position: "absolute",
    right: 16,
    bottom: 16,
  },

  comingSoon: {
    marginHorizontal: defaultHorizontalMargin,
    maxWidth: "100%",
    alignItems: "center",
    flex: 1,
  },

  comingSoonText: {
    fontFamily: Base.fonts.appBold,
    fontSize: 16,
    color: Base.colours.appGrey,
    textAlign: "center",
    marginTop: 40,
  },

  username: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 12,
    color: "white",
    marginHorizontal: 1,
  },

  groupedSwitches: {
    backgroundColor: Base.colours.appWhite,
    borderRadius: 16,
    marginBottom: 16,
  },

  banner: {
    backgroundColor: Base.colours.appDarkBlue,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
  },

  bannerText: {
    color: Base.colours.appWhite,
    fontSize: 16,
    textAlign: "center",
    fontWeight: "600",
  },

  commonButton: {
    borderRadius: 54,
    overflow: "hidden",
    marginTop: 16,
  },
});
