import moment from "moment";
import Actions, {
  SET_CREDENTIALS,
  SET_VENUE,
  PAUSE_MENU,
  UNPAUSE_MENU,
  SET_ORDERS,
  ORDER_DETAILS,
  ADD_ORDER,
  LOGOUT,
  SET_CONNECTED,
  SET_SERVICES_STATE,
  SET_END_TIME,
  SET_RECENT_VENUE,
  SET_ME,
  SET_ORDER,
} from "./Actions";
import Functions from "../../base/Functions";

const initialState = {
  venue: { oat: { menus: { topMenu: {}, bottomMenu: {} } }, orderingMods: {} },
  credentials: {},
  permissions: {},
  roles: [],
  connected: false,
  orders: [],
  recentVenue: null,
  me: null,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER: {
      let orders = state.orders;
      const replacementOrder = action.order;
      const replacementIndex = oldOrders.findIndex((o) => o._id);
      if (replacementIndex !== -1) {
        orders[replacementIndex] = replacementOrder;
      }
      return {
        ...state,
        orders,
      };
    }
    case SET_ME: {
      return {
        ...state,
        me: action.me,
      };
    }
    case SET_RECENT_VENUE: {
      return {
        ...state,
        recentVenue: action.venue,
      };
    }
    case SET_END_TIME: {
      const venue = Functions.setNestedProperty(
        { ...state.venue },
        `orderingUntil`,
        action.time
      );
      return {
        ...state,
        venue,
      };
    }
    case SET_SERVICES_STATE: {
      const venue = Functions.setNestedProperty(
        { ...state.venue },
        `orderingMods`,
        action.services
      );
      return {
        ...state,
        venue,
      };
    }
    case SET_CREDENTIALS:
      return {
        ...state,
        credentials: action.credentials,
      };
    case SET_VENUE:
      return {
        ...state,
        venue: action.venue,
      };
    case PAUSE_MENU: {
      const venue = Functions.setNestedProperty(
        { ...state.venue },
        `oat.menus.${action.key}.pausedUntil`,
        moment().utc().add(30, "minutes").format()
      );
      console.log("REDUCER: ", JSON.stringify(venue));
      return {
        ...state,
        venue,
      };
    }
    case UNPAUSE_MENU: {
      const v = { ...state.venue };
      if (v.oat.menus && v.oat.menus[action.key] && v.oat.menus[action.key])
        delete v.oat.menus[action.key].pausedUntil;
      return {
        ...state,
        venue: v,
      };
    }
    case SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    case ORDER_DETAILS:
      return {
        ...state,
        orders: [
          ...state.orders.filter((x) => x._id != action.order._id),
          action.order,
        ],
      };
    case ADD_ORDER:
      console.log({ newOrder: action.order });
      return {
        ...state,
        orders: [...state.orders, action.order],
      };
    case SET_CONNECTED:
      return {
        ...state,
        connected: action.connected,
      };
    case LOGOUT:
      return { ...initialState, recentVenue: state.recentVenue };
    default:
      return state;
  }
}
