import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';

export default function EmptyOrderListItem(props) {

    return (
        <Animatable.View style={[styles.main, Platform.OS === 'web' && {width:'100%'}]} animation={'fadeIn'} delay={props.delay} duration={200}>
            <View style={styles.cardShadow}>
    		    <View style={styles.card}> 
                    <View style={styles.time}>
                       <View style={styles.timeText}/>
                    </View>
                    <View style={{flex:1}}>
                        <View style={styles.infoContainer}>
                            <View style={{flex:1}}>
                                <View style={[styles.label, {width:'50%'}]}/>
                                <View style={[styles.label, {width:'40%'}]}/>
                                <View style={[styles.label, {width:'60%'}]}/>
                            </View>
                            <View style={styles.icon}/>
                           </View>
                        <View style={styles.buttonsContainer}>
                            <View style={styles.button}/>
                            <View style={{width: 12}}/>
                            <View style={styles.button}/>
                        </View>
                    </View>
		        </View>
            </View>
        </Animatable.View>
	);
}


const styles = StyleSheet.create({
    main: {
        flex: 1,
        maxWidth:400,
				marginVertical: 8,
				alignSelf:'center',
        flexDirection: 'row',
    },

    cardShadow: {
        flex:1,
        backgroundColor: 'white',
        borderRadius: 4,
        shadowOpacity: 0.15,
        elevation: 2,
        shadowOffset: {
            width:1,
            height:1
        }
    },

    card: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: 1,
        borderRadius:4,
    },

    time: {
        width: 93,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#a9b7be',
    },

    timeText: {
        width: '75%',
        height:21,
        marginBottom:40,
        borderRadius:10.5,
        backgroundColor: '#ffffff30'
    },

    label: {
        height:15,
        borderRadius:7.5,
        marginBottom:12,
        backgroundColor: '#a9b7be30'
    },

    infoContainer: {
        flex: 1, 
        flexDirection: 'row',
        paddingHorizontal: 12,
        paddingTop: 12
    },


    icon: {
        alignSelf: 'center',
        width:20,
        height: 20,
        marginHorizontal:7,
    },

    buttonsContainer: {
        flexDirection: 'row',
        marginHorizontal: 12,
        marginBottom: 12,
        marginTop: 0
    },

    button: {
        flex:1,
        height: 36,
        borderRadius: 18,
        shadowOpacity:0,
        borderRadius:18,
        borderWidth: 2,
        borderColor: '#eef1f2'
    },
})