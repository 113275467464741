import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { StatusBar, Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import RootScreen from "../../RootScreen";
import SettingsScreenV1 from "../settings/SettingsScreen";
import SettingsScreenV2 from "../settings/SettingsScreenV2";
import WaitTimeScreen from "../settings/waittime/WaitTimeScreen";
import EndTimeScreen from "../settings/endtime/EndTimeScreen";
import VenueSelectScreen from "../venue/VenueSelectScreen";
import { useDispatch, useSelector } from "react-redux";

export default function (props) {
  const { title, linking, Base } = props;
  const RootStack = createStackNavigator();
  const user = useSelector((state) => state.credentials);
  const venue = useSelector((state) => state.venue);
  const { userVersion } = user;
  const SettingsScreen =
    userVersion === 2 ? SettingsScreenV2 : SettingsScreenV1;

  return (
    <NavigationContainer linking={linking}>
      <StatusBar
        barStyle="light-content"
        backgroundColor={Base.colours.appDarkYellow}
      />
      <RootStack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
        <RootStack.Screen
          name="Root"
          component={RootScreen}
          options={{ title: title(""), headerRightContainerStyle: {width: "40%"} }}
        />
        <RootStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{ title: title("Settings"), headerRightContainerStyle: {width: "40%"} }}
        />
        <RootStack.Screen
          name="WaitTime"
          component={WaitTimeScreen}
          options={{ title: title("Settings") }}
        />
        <RootStack.Screen
          name="EndTime"
          component={EndTimeScreen}
          options={{ title: title("Settings") }}
        />
        <RootStack.Screen
          name="VenueSelect"
          component={VenueSelectScreen}
          options={{ title: title("Venue Select") }}
        />
      </RootStack.Navigator>
    </NavigationContainer>
  );
}
