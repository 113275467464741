import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Base from "../../base";

const ScreenBanner = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Base.colours.appDarkBlue,
        paddingHorizontal: 8,
        paddingVertical: 4,
        minHeight: 24
    },
    text: {
        fontFamily: Base.fonts.appSemiBold,
        fontSize: 12,
        color: Base.colours.appWhite,
        letterSpacing: 0,
        lineHeight: 16,
        textAlign: "center"
    }
})

export default ScreenBanner;
