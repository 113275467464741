import React, { useState, useRef } from 'react';
import { TouchableOpacity, View, StyleSheet, TextInput, Keyboard, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import Base, { Firebase } from '../../base';
import Constants from '../../base/Constants';
import { Button } from 'react-native-elements'
import HeaderUsername from '../common/HeaderUsername';

export default function SearchBar(props) {
	const [inSearch, setInSearch] = useState(false);
	const [query, setQuery] = useState('');
	const tf = useRef();

	function searchTouched() {
		const searching = !inSearch;
		setInSearch(searching);

		if (searching) {
			setTimeout(() => { if (Platform.OS !== 'web') tf.current.focus() }, 500);
		}
	}

	function onChangeText(text) {
		setQuery(text);
		props.onQueryChange(text.toLowerCase());
	}

	function onSubmit() {
		Keyboard.dismiss()
	}

	function cancelSearch() {
		setQuery('');
		props.onQueryChange('');
		setInSearch(false);
	}

	return (
		<View style={styles.main}>
			{!props.isMobile && !inSearch && <HeaderUsername />}
			{<TouchableOpacity style={styles.menuButton} onPress={searchTouched}>
				<Icon name='search' size={22} color='white' />
			</TouchableOpacity>}
			<TouchableOpacity style={styles.menuButton} onPress={() => {
				props.navigation.push('Settings');
				Firebase.logEvent('settings');
			}}>
				<Icon name='settings' size={22} color='white' />
			</TouchableOpacity>
			{inSearch && <View style={[styles.searchBarContainer,
				Platform.OS === 'android' && styles.searchBarContainerAndroid, 
				Platform.OS === 'web' ? styles.searchBarContainerWeb :  
				{ width: Constants.window.width - 16, maxWidth: props.isMobile? '100%': 300}]}>
				<Icon name='search' size={22} color={Base.colours.appRed} />
				<TextInput
					ref={tf}
					style={styles.searchBar}
					onChangeText={onChangeText}
					value={query}
					placeholder={'Search'}
					placeholderTextColor={Base.colours.appGrey}
					onSubmitEditing={onSubmit} />
					<Button title='Cancel' type='clear' titleStyle={styles.cancelTitle} buttonStyle={styles.searchCancel} onPress={cancelSearch}/>
			</View>}
		</View>
	)
}


const styles = StyleSheet.create({
	main: {
		marginRight: 8,
		flexDirection: 'row',
		flex: 1,
		alignItems: 'center',
	},

	menuButton: {
		padding:8,
	},

	searchBarContainer: {
		position: 'absolute',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: 'white',
		right: 0,
		top: 0,
		height: 38,
		borderRadius: 24,
		paddingLeft: 10,
	},

	searchBarContainerAndroid: {
		top: 8,
		height: 40,
	},

	searchBarContainerWeb: {
		top: 12,
		height: 40,
	},

	searchBar: {
		flex: 1,
		height: 38,
		minHeight: 38,
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		paddingLeft: 6,
		fontFamily: Base.fonts.appRegular,
		fontSize: 16,
	},

	searchCancel: {
		height: '100%',
		alignContent: 'center',
		marginRight: 5
	},

	cancelTitle: {
		color: 'black',
		textAlign: 'center',
		fontFamily: Base.fonts.appRegular,
		fontSize: 12,
	}
})