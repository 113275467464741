import React from 'react';
import { Image, View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';


export default function HeaderLogo(props) {
	const b = useSelector(state => state.business)
	const isMobile = useMediaQuery({ maxWidth: 767 });

	function brandImage() {
		let img = b && b.affiliation && b.affiliation.scheme && b.affiliation.scheme.admin && b.affiliation.scheme.admin.logo;
		if (!img || img === null) {
			img = b && b.branding && b.branding.admin && b.branding.admin.logo
		}
		return img;
	}

	return (
		<View style={styles.main}>
			<Image
				style={styles.headerLogoImage}
				source={require('../../assets/images/company-logo/logo.png')} />
			{(!isMobile || !brandImage()) && <Text style={styles.headerTitle} textBreakStrategy={'simple'}>Dashboard </Text>}
			<View style={{ width: 5 }} />
			{brandImage() && <Image style={styles.brandImage} source={{ uri: brandImage() }} />}
		</View>
	);
}


const styles = StyleSheet.create({
	main: {
		flexDirection: 'row',
		marginStart: 12,
		marginBottom: 3,
		alignItems: 'center'
	},

	headerLogoImage: {
		resizeMode: 'contain',
		width: 120,
		height: 55
	},

	brandImage: {
		resizeMode: 'contain',
		width: 90,
		height: 40
	},

	headerTitle: {
		marginTop: 5,
		marginLeft: 1,
		width: 65,
		color: '#084c61',
		fontSize: 12,
		fontFamily: 'OpenSans-SemiBold',
	}
})

