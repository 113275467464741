export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const SET_VENUE = "SET_VENUE";
export const SET_ORDERS = "SET_ORDERS";
export const SET_ORDER = "SET_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const LOGOUT = "LOGOUT";
export const SET_CONNECTED = "SET_CONNECTED";
export const PAUSE_MENU = "PAUSE_MENU";
export const UNPAUSE_MENU = "UNPAUSE_MENU";
export const SET_SERVICES_STATE = "SET_SERVICES_STATE";
export const SET_END_TIME = "SET_END_TIME";
export const SET_RECENT_VENUE = "SET_RECENT_VENUE";
export const SET_ME = "SET_ME";

function setRecentVenue(venue) {
  return { type: SET_RECENT_VENUE, venue };
}

function setCredentials(credentials) {
  return { type: SET_CREDENTIALS, credentials };
}

function setVenue(venue) {
  return { type: SET_VENUE, venue };
}

function pauseMenu(key) {
  return { type: PAUSE_MENU, key };
}

function unPauseMenu(key) {
  return { type: UNPAUSE_MENU, key };
}

function setOrders(orders) {
  return { type: SET_ORDERS, orders };
}

function addOrder(order) {
  return { type: ADD_ORDER, order };
}

function setOrderDetails(order) {
  return { type: ORDER_DETAILS, order };
}

function logout(state) {
  return { type: LOGOUT, state };
}

function setConnected(connected) {
  return { type: SET_CONNECTED, connected };
}

function setServicesState(services) {
  return { type: SET_SERVICES_STATE, services };
}

function setEndTime(time) {
  return { type: SET_END_TIME, time };
}

function setMe(me) {
  return { type: SET_ME, me };
}

function setOrder(order) {
  return { type: SET_ORDERS, order };
}

export default {
  setCredentials,
  setOrders,
  setOrder,
  setOrderDetails,
  addOrder,
  logout,
  setVenue,
  pauseMenu,
  unPauseMenu,
  setConnected,
  setServicesState,
  setEndTime,
  setRecentVenue,
  setMe,
};
