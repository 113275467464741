import React, { useState, useEffect, useMemo } from "react";
import {
  View,
  StyleSheet,
  AppState,
  Text,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import Base, { MQTT, Api, Actions, Firebase } from "../../base";
import { OfflineBanner, Tabs } from "../common";
import LiveIndicator from "./LiveIndicator";
import OrdersList from "./list/OrdersList";
import OrderDetailsView from "./details/OrderDetailsView";
import SearchBar from "./SearchBar";
import RadioPicker from "./RadioPicker";
import { useUsername } from "../../modules/hooks";
import ScreenBanner from "../common/ScreenBanner";

function headerOptions(navigation, queryChanged, isMobile) {
  return {
    ...Base.constants.headerOptions(false, "Orders"),
    headerRight: () => (
      <SearchBar
        navigation={navigation}
        onQueryChange={queryChanged}
        isMobile={isMobile}
      />
    ),
    headerLeft: () => <LiveIndicator />,
  };
}

export default function OrdersScreen(props) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(Base.constants.mobile);
  const allOrders = useSelector((state) =>
    state.orders.sort((a, b) => moment.utc(b.createdAt).diff(a.createdAt))
  );
  const user = useSelector((state) => state.credentials);
  const { userVersion } = user;
  const [isRefreshing, setRefreshing] = useState(false);
  const [detailsOrder, setDetailsOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersTabIndex, setOrdersTabIndex] = useState(0);
  const [query, setQuery] = useState("");
  const [sortIndex, setSortIndex] = useState(1);
  const [orderDetailsModal, setOrderDetailsModal] = useState("main");
  const sortButtons = ["Collection", "Submission"];
  const getTabs = (orders) => {
    const orderedOrders = orders.filter((o) => o.status === "ordered");
    const readyOrders = orders.filter((o) => o.status === "ready");
    const completedOrders = orders.filter(
      (o) => o.status === "completed" || o.status === "cancelled"
    );
    const orderedTab = {
      name: "ordered",
      number: orderedOrders.length,
      label: "Pending",
      items: orderedOrders,
    };
    const readyTab = { name: "ready", label: "Accepted", items: readyOrders };
    const completedTab = {
      name: "completed",
      label: "Completed",
      items: completedOrders,
    };
    return [orderedTab, readyTab, completedTab];
  };
  const tabs = useMemo(() => getTabs(orders), [orders]);

  const selectedTab = tabs[ordersTabIndex];
  const whichOrders = orders?.length > 0 ? orders : allOrders;
  const shownOrders = userVersion !== 2 ? whichOrders : selectedTab?.items;
  useEffect(() => {
    getOrders();
  }, []);
  async function getOrders() {
    setRefreshing(true);
    const venue = await Api.getVenue();
    dispatch(Actions.setVenue(venue));

    const o = await Api.getOrders(venue);
    dispatch(Actions.setOrders(o));
    sortOrders(o, sortIndex);
    setRefreshing(false);
    MQTT.connect(venue.uniqueRef, onMQTTMEssage);
  }

  useEffect(() => {
    AppState.addEventListener("change", stateChanged);
    return () => {
      AppState.removeEventListener("change", stateChanged);
    };
  }, []);
  const stateChanged = (state) => {
    state === "active" && getOrders();
    (state === "inactive" || state === "background") && MQTT.disconnect();
  };

  async function pullToRefresh() {
    setRefreshing(true);
    setTimeout(async function () {
      await getOrders();
      setRefreshing(false);
    }, 1000);
  }

  async function orderTapped(order) {
    console.log("order tapped");
    Firebase.logEvent("order_details");
    setDetailsOrder(order);
    if (order.basket) return;
    setDetailsOrder({ ...order, isUpdating: true });
    const detOrder = await Api.getOrder(order._id);
    if (detOrder && detOrder.basket) {
      dispatch(Actions.setOrderDetails(detOrder));
      setDetailsOrder(detOrder);
    }
  }
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function setOrder(order) {
    dispatch(Actions.setOrder(order));
  }
  async function setOrderStatus({ order, status }) {
    if (status === "cancelled") {
      await setOrderDetailsModal("refundReason");
      await orderTapped(order);
    } else {
      Firebase.logEvent("order_state");
      const { venueRef, id } = order;
      const loadingOrder = await orders.map((o) => {
        if (o.id === order.id) {
          o.status = "loading";
        }
        return o;
      });
      await setOrders(loadingOrder);
      const res = await Api.setOrderStatus({
        orderId: id,
        venueId: venueRef,
        status,
      });
      if (res && res !== "error") {
        const newOrders = await orders.map((o) => {
          if (o.id === order.id) {
            o = res;
          }
          return o;
        });
        await setOrders(newOrders);
      } else {
        const newOrders = await orders.map((o) => {
          if (o.id === order.id) {
            o.status = "error";
          }
          return o;
        });
        await setOrders(newOrders);
        await sleep(2000);
      }
      getOrders();
    }
  }

  async function onMQTTMEssage(orderId, action) {
    const order = await Api.getOrder(orderId);
    if (action === "order.new") {
      if (order && order.basket) {
        Base.functions.showNewOrderMessage(order, () => orderTapped(order));
        dispatch(Actions.addOrder(order));
        getOrders();
      }
    } else if (action === "auto_rejected_order") {
      Base.functions.showRejectedMessage(order);
    }
  }

  function onSearchQueryChange(q) {
    setQuery(q);
    if (!q || !q.length) {
      setOrders(allOrders);
      return;
    }
    const matchedOrders = allOrders.filter((x) => {
      const name = (x.customer.firstName ?? "").toLowerCase();
      const email = (x.customer.email ?? "").toLowerCase();
      const phone = (x.customer.phoneNumber ?? "").toLowerCase();
      return (
        name?.includes(q) ||
        email?.includes(q) ||
        x?.reference?.includes(q) ||
        phone?.includes(q)
      );
    });
    sortOrders(matchedOrders, sortIndex);
  }
  props.navigation.setOptions(
    headerOptions(props.navigation, onSearchQueryChange, isMobile)
  );

  function sortOrders(orders, index) {
    const sortedOrders = [...orders].sort((a, b) => {
      if (index === 0) {
        return moment.utc(a.timeslot).diff(moment.utc(b.timeslot));
      } else {
        return moment.utc(b.createdAt).diff(moment.utc(a.createdAt));
      }
    });
    setOrders(sortedOrders);
  }

  function sortOptionChanged(index) {
    setSortIndex(index);
    sortOrders(orders, index);
  }

  const username = useUsername()

  if (query.length > 0 && !orders.length) {
    return (
      <View style={styles.main}>
        {!!isMobile && <ScreenBanner text={username} />}
        <Text style={styles.noResults}>
          {
            "Sorry there are no orders that match\nyour search. Please try again."
          }
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.main}>
      {!!isMobile && <ScreenBanner text={username} />}
      <RadioPicker
        key="chefView"
        title={"Sort by:"}
        buttons={sortButtons}
        selectedIndex={sortIndex}
        onButtonSelect={sortOptionChanged}
      />
      {user?.userVersion === 2 && (
        <Tabs
          tabs={tabs}
          onPress={setOrdersTabIndex}
          selectedTab={ordersTabIndex}
        />
      )}
      <OrdersList
        orders={shownOrders}
        onMoreInfo={orderTapped}
        onPress={setOrderStatus}
        onRefresh={pullToRefresh}
        sortIndex={sortIndex}
        isRefreshing={isRefreshing}
        user={user}
      />
      <OrderDetailsView
        onDismiss={() => {
          setDetailsOrder(null);
          setOrderDetailsModal("main");
        }}
        order={detailsOrder}
        isMobile={isMobile}
        visible={detailsOrder != null}
        modalName={orderDetailsModal}
        setModalName={setOrderDetailsModal}
        getOrders={getOrders}
      />
      <OfflineBanner />
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    display: "flex",
    backgroundColor: Base.colours.appLightGrey,
  },

  settings: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },

  noResults: {
    marginHorizontal: 30,
    maxWidth: 400,
    textAlign: "center",
    fontFamily: Base.fonts.appExtraBold,
    color: Base.colours.appDarkBlue,
    fontSize: 15,
    marginTop: 50,
  },

  chefButton: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 6,
    borderColor: Base.colours.appRed,
    borderWidth: 2,
  },

  chefTitle: {
    fontFamily: Base.fonts.appBold,
    color: Base.colours.appDarkBlue,
    fontSize: 14,
  },
});
