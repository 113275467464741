import React from "react";
import { TouchableOpacity, View, StyleSheet, Text } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import Base from "../../base";

export default function RadioPicker(props) {
  return (
    <View style={[styles.main, props.style]}>
      <Text style={styles.title}>{props.title}</Text>
      {props.buttons.map((x, i) => {
        const selected = props.selectedIndex === i;
        return (
          <TouchableOpacity
            key={i}
            style={styles.button}
            onPress={() => props.onButtonSelect(i)}
          >
            <Icon
              name={selected ? "radio-button-on" : "radio-button-off"}
              color={selected ? Base.colours.appRed : "black"}
              size={22}
            />
            <Text style={styles.buttonTitle}>{x}</Text>
          </TouchableOpacity>
        );
      })}
      <View style={{ flex: 1 }} />
      {props.rightView}
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 12,
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    backgroundColor: "white",
    minHeight: 45,
    maxHeight: 45,
  },

  title: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 14,
    color: "black",
    marginRight: 6,
  },

  button: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 8,
  },

  buttonTitle: {
    fontFamily: Base.fonts.appSemiBold,
    fontSize: 14,
    color: "black",
    marginLeft: 8,
  },
});
