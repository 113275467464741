import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import { SearchBar } from "react-native-elements";
import Base, { Api, Actions, Firebase } from "../../base";
import { ExpandableButton, LoadingView } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
var jwtDecode = require("jwt-decode");
import Icon2 from "react-native-vector-icons/Feather";

function settingsHeader(navigation, logout, isMobile, user) {
  const jwt = jwtDecode(user?.idToken ?? "");
  const username = jwt["email"]?.length > 0 ? jwt["email"] : jwt["nickname"];

  return {
    ...Base.constants.headerOptions(false, ""),
    headerRight: () => (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!isMobile && username && (
          <Text style={styles.username}>{username}</Text>
        )}
        <TouchableOpacity style={Base.styles.headerButton} onPress={logout}>
          <Icon2 name="log-out" size={24} color="white" />
        </TouchableOpacity>
      </View>
    ),
  };
}

export default function VenueSelectScreen(props) {
  const { navigation, fetchConfig, isV2User } = props;
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(Base.constants.mobile);
  const credentials = useSelector((state) => state.credentials);
  const recentVenue = useSelector((state) => state.recentVenue);
  const me = useSelector((state) => state.me);
  const venues = me?.venues;
  const venueType = me?.business?.venuesType || "venue";
  const asyncFetchConfig = async () => {
    await setLoading(true);
    await fetchConfig();
    setLoading(false);
  };
  useEffect(() => {
    if (isV2User && !me) {
      asyncFetchConfig();
    }
  }, []);
  const searchVenues = ({ search, venues }) => {
    if (!search.length) {
      return venues;
    }
    const payload = venues.filter((venue) => {
      const { name, uniqueRef } = venue;
      return !!(name?.includes(search) || uniqueRef?.includes(search));
    });
    return payload;
  };
  const handleVenueSelect = async (venue) => {
    const venueId = venue?.uniqueRef;
    const creds = {
      ...credentials,
      venueId,
    };
    dispatch(Actions.setRecentVenue(venue));
    dispatch(Actions.setCredentials(creds));
    navigation.push("Root");
  };
  async function logout() {
    await Base.functions.logoutUser(() => {
      props.navigation.goBack();
      Firebase.logEvent("logout_settings");
    });
  }
  const handleSearch = async (value) => {
    setSearch(value);
  };
  props.navigation.setOptions(
    settingsHeader(props.navigation, logout, isMobile, credentials)
  );
  return (
    <View style={styles.container}>
      <Text style={styles.pageTitle}>{`Select ${venueType}`}</Text>
      <SearchBar
        placeholder="Search"
        onChangeText={handleSearch}
        value={search}
        lightTheme={true}
        round={true}
        inputStyle={styles.backgroundWhite}
        leftIconContainerStyle={styles.backgroundWhite}
        containerStyle={styles.backgroundTransparent}
        inputContainerStyle={[styles.backgroundWhite, styles.inputContainer]}
        searchIcon={{ color: Base.colours.appRed, size: 35 }}
      />
      {recentVenue && !loading && (
        <View>
          <Text style={styles.sectionHeading}>Most recent selection:</Text>
          <ExpandableButton
            key={recentVenue?.uniqueRef}
            style={styles.expandableButton}
            title={`${recentVenue?.name ? `${recentVenue?.name}: ` : ""}${
              recentVenue?.externalRef || recentVenue?.uniqueRef
            }`}
            onPress={() => handleVenueSelect(recentVenue)}
            static={true}
          />
        </View>
      )}
      <Text style={styles.sectionHeading}>{`All ${venueType}s`}</Text>
      {loading && <LoadingView title={loading} />}
      {venues && !!venues.length && (
        <View>
          {searchVenues({ venues, search }).map((venue) => {
            return (
              <ExpandableButton
                key={venue?.uniqueRef}
                style={styles.expandableButton}
                title={`${venue?.name ? `${venue?.name}: ` : ""}${
                  venue?.externalRef || venue?.uniqueRef
                }`}
                onPress={() => handleVenueSelect(venue)}
                static={true}
              />
            );
          })}
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 32,
  },
  backgroundWhite: {
    backgroundColor: Base.colours.appWhite,
  },
  backgroundTransparent: {
    backgroundColor: "transparent",
    borderTopWidth: 0,
    borderBottomWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  inputContainer: {
    borderRadius: 54,
  },
  pageTitle: {
    color: Base.colours.appDarkBlue,
    fontSize: 26,
    fontFamily: Base.fonts.appExtraBold,
  },
  sectionHeading: {
    marginVertical: 12,
  },
  username: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 12,
    color: "white",
    marginHorizontal: 1,
  },
});
