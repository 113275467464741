import React from 'react';
import { TouchableOpacity, Text, StyleSheet  } from 'react-native';
import Base from '../../../base'


export default function OrderDetailsItem(props) {

	return ( 
		<TouchableOpacity style={[styles.main, props.mainStyle || {}]} onPress={props.onPress} activeOpacity={0.9}>
			<Text style={Base.styles.body}>{props.title}</Text> 
			<Text style={[styles.value, props.style]}>{props.value}</Text>  
		</TouchableOpacity>                    
	);
}


const styles = StyleSheet.create({
	main: {
		width: '47%',
	},

	value: {
		fontFamily: Base.fonts.appBold,
		color: Base.colours.appDarkBlue,
		marginTop:4,
		fontSize: 17,
	},
})