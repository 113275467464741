import firebase from "firebase/app";
import "firebase/remote-config";
import "firebase/analytics";

const firebaseConfigDefault = {
  apiKey: "AIzaSyBI31HqQaN1cYj1Zg32ppUOPs_1aHfEU8E",
  authDomain: "orderbee-dashboard-iorder.firebaseapp.com",
  databaseURL: "https://orderbee-dashboard-iorder.firebaseio.com",
  projectId: "orderbee-dashboard-iorder",
  storageBucket: "orderbee-dashboard-iorder.appspot.com",
  messagingSenderId: "1003986931858",
};

const firebaseConfigDev = {
  ...firebaseConfigDefault,
  appId: "1:1003986931858:web:32ce4edabcb9f509d8964b",
  measurementId: "G-YGHLTP2SCZ",
};

const firebaseConfigQA = {
  ...firebaseConfigDefault,
  appId: "1:1003986931858:web:83ebe868ac94ce9bd8964b",
  measurementId: "G-4J8BENRYVX",
};

const firebaseConfigProd = {
  ...firebaseConfigDefault,
  appId: "1:1003986931858:web:a1e0eac97aa89b10d8964b",
  measurementId: "G-CV345EKQF2",
};

let config;
const env = process.env?.APP_MANIFEST?.extra?.webEnv;
console.log({ env });

// Set the config based on the webEnv
switch (env) {
  case "prod":
    config = firebaseConfigProd;
    break;
  case "qa":
    config = firebaseConfigQA;
    break;
  default:
    config = firebaseConfigDev;
    break;
}

const app = firebase.initializeApp(config);
const remoteConfig = app.remoteConfig();

async function init() {
  await remoteConfig.fetch();
  await remoteConfig.activate();
  return true;
}

async function registerForPush() {
  return null;
}

function logEvent(name, args) {
  // TODO - fix this on web
  // app.analytics.logEvent(name, args);
}

function getConfigValue(key) {
  const value = remoteConfig.getValue(key)._value;
  return value || "";
}

async function getPushToken() {
  return null;
}

export default {
  init,
  registerForPush,
  logEvent,
  getConfigValue,
  getPushToken,
};
