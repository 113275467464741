import './shim'
import mqtt from 'mqtt';
import mqttWs from './mqttWs';
import { getSignedUrl } from './sigV4Utils'
import Firebase from '../firebase'
import Store from '../store';
import Actions from '../store/Actions';

let pendingSub = false;
let client = null;
let topic = null;
let onMessageCallback = null;


function connect(venueId, callback) {
	disconnect();
	const IOT_ENDPOINT = Firebase.getConfigValue('mqtt_endpoint');
  const AWS_ID = Firebase.getConfigValue('mqtt_aws_id');
  const AWS_KEY = Firebase.getConfigValue('mqtt_aws_key');
  const AWS_REGION = Firebase.getConfigValue('mqtt_aws_region');

  const url = getSignedUrl(
      IOT_ENDPOINT, 
      AWS_REGION, 
      AWS_ID,
      AWS_KEY
  );

  function wrapper(client) {
    return mqttWs(client, { url });
  }
  client = mqtt.MqttClient(wrapper, { url });
  client.on('message', onMessage);
  client.on('connect', onConnect);
	client.on('close', onClose);

  topic = Firebase.getConfigValue('mqtt_topic') + venueId;
  pendingSub = true;
  onMessageCallback = callback;
}


function disconnect() {
	client && client.end(true);
}



function subscribe() {
  client.subscribe(topic);
  pendingSub = false;
	console.log('MQTT SUBSCRIBED: ', topic);
	Store.dispatch(Actions.setConnected(true));
}
  

function onMessage(_, buffer) {
  let message = JSON.parse(buffer.toString())
  console.log('MQTT MESSAGE RECEIVED: ', buffer.toString());
  if (message && message.meta && message.action) {
		onMessageCallback(message.meta.orderId, message.action);
  }
}


function onConnect() {
  console.log('MQTT CONNECTED');
  if (pendingSub) {
    subscribe();
  }
}


function onClose() {
  console.log("MQTT CLOSED");
	Store.dispatch(Actions.setConnected(false));
}




export default { 
  connect,
  disconnect,
}
