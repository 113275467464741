
import 'react-native-get-random-values';
import { nanoid } from 'nanoid';
// import * as Sentry from "@sentry/react-native";
import Firebase from '../firebase';
import Store from '../store';

function init() {

		// let dsn = Firebase.getConfigValue('sentry_dsn') || '';
    // Sentry.init({ dsn });

    // const id = nanoid();
    // Sentry.setTag('sessionId', id)
}


function setUserId() {
    // const auth0Id = Store.getState().credentials.userId;
    // if (auth0Id) {
    //     Sentry.setUser({ id: auth0Id });
    // }
}


function addBreadcrumb(args) {
    
    // setUserId();
    // const data = args.data
    // const message = args.message;
    // const venue = Store.getState().venue;

    // Sentry.configureScope((scope) => {
    //     if (venue && venue._id) {
    //         scope.setTag('venueId', venue._id)
    //     }

    //     scope.addBreadcrumb({
    //         message,
    //         data,
    //     });
    // });
}


function logError(error) {
    
    // setUserId();
    // const venue = Store.getState().venue;

    // Sentry.configureScope((scope) => {
    //     if (venue && venue._id) {
    //         scope.setTag('venueId', venue._id)
    //     }
    // });
    // Sentry.captureException(error);
}

function logMessage(message) {

    // setUserId();
    // const venue = Store.getState().venue;
    
    // Sentry.configureScope((scope) => {
    //     if (venue && venue._id) {
    //         scope.setTag('venueId', venue._id)
    //     }
    // });
    // Sentry.captureMessage(message);
}


export default { 
    init,
    logError,
    logMessage,
    addBreadcrumb
}