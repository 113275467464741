import React from 'react';
import { StyleSheet, TextInput } from 'react-native';
import Base from '../../base';


export default function TextField(props) {
	return (
    <TextInput 
      style={[styles.main, props.style]} 
      onChangeText={props.onChangeText}
      value={props.value}
      placeholder={props.placeholder}
      clearButtonMode={props.clearButtonMode ?? 'always'}
      placeholderTextColor={Base.colours.appGrey}/>
	);
}


const styles = StyleSheet.create({
    main: {
        flex:1,
				height: 48,
				minHeight:48,
        borderRadius: 24,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderColor: Base.colours.appSemiLightGrey,
        borderWidth:1,
        paddingHorizontal:21,
        fontFamily: Base.fonts.appRegular,
        fontSize: 16,
        marginBottom: 15
    },
})