// sigV4Utils.js

// import AWS from 'aws-sdk/dist/aws-sdk-react-native';

import AWS from 'aws-sdk'

function getSignatureKey(key, date, region, service) {
  const kDate = AWS.util.crypto.hmac(`AWS4${key}`, date, 'buffer');
  const kRegion = AWS.util.crypto.hmac(kDate, region, 'buffer');
  const kService = AWS.util.crypto.hmac(kRegion, service, 'buffer');
  const kCredentials = AWS.util.crypto.hmac(kService, 'aws4_request', 'buffer');
  return kCredentials;
};


function getUrl(host, region, credentials) {
  const datetime = AWS.util.date.iso8601(new Date()).replace(/[:-]|\.\d{3}/g, '');
  const date = datetime.substr(0, 8);

  const method = 'GET';
  const protocol = 'wss';
  const uri = '/mqtt';
  const service = 'iotdevicegateway';
  const algorithm = 'AWS4-HMAC-SHA256';

  const credentialScope = `${date}/${region}/${service}/aws4_request`;
  let canonicalQuerystring = `X-Amz-Algorithm=${algorithm}`;
  canonicalQuerystring += `&X-Amz-Credential=${encodeURIComponent(`${credentials.accessKeyId}/${credentialScope}`)}`;
  canonicalQuerystring += `&X-Amz-Date=${datetime}`;
  canonicalQuerystring += '&X-Amz-SignedHeaders=host';

  const canonicalHeaders = `host:${host}\n`;
  const payloadHash = AWS.util.crypto.sha256('', 'hex');
  const canonicalRequest = `${method}\n${uri}\n${canonicalQuerystring}\n${canonicalHeaders}\nhost\n${payloadHash}`;

  const stringToSign = `${algorithm}\n${datetime}\n${credentialScope}\n${AWS.util.crypto.sha256(canonicalRequest, 'hex')}`;
  const signingKey = getSignatureKey(credentials.secretAccessKey, date, region, service);
  const signature = AWS.util.crypto.hmac(signingKey, stringToSign, 'hex');

  canonicalQuerystring += `&X-Amz-Signature=${signature}`;
  if (credentials.sessionToken) {
    canonicalQuerystring += `&X-Amz-Security-Token=${encodeURIComponent(credentials.sessionToken)}`;
  }

   const requestUrl = `${protocol}://${host}${uri}?${canonicalQuerystring}`;
  return requestUrl;
};

function getSignedUrl(host, region, id, key) {
    AWS.config.region = region;
    AWS.config.credentials = new AWS.Credentials(id, key);
    return getUrl(host, AWS.config.region, AWS.config.credentials)
};


export { getSignatureKey, getSignedUrl};