import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import Base, { Firebase } from '../../base'
import { useSafeArea } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive'

export default function ContactButton(props) {
    const email = Firebase.getConfigValue('support_email');
    const isMobile = useMediaQuery(Base.constants.mobile)
    const insets = useSafeArea();

    function onPress() {
        Firebase.logEvent('support_' + props.screen);
        Base.functions.sendEmail(email)
    }

	return (
        <View style={[styles.main, props.style, {paddingBottom:insets.bottom > 0 ? insets.bottom : 18, justifyContent: isMobile? 'center' : 'flex-start'}]}>
	    	<TouchableOpacity style={styles.button} onPress={onPress}> 
		    	<Text style={styles.title}>Support / Contact</Text>
                <Text style={styles.number}>{email}</Text>
		    </TouchableOpacity>
        </View>
	);
}


const styles = StyleSheet.create({
    main: {
        width: '100%',
        padding: 18,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    
    button: {
        flexDirection: 'row',
    }, 

    title: {
        marginEnd: 10,
        fontSize: 15,
        color: Base.colours.appBlack,
        fontFamily: Base.fonts.appRegular
    },

    number: {
        fontSize: 15,
        color: Base.colours.appBlack,
        fontFamily: Base.fonts.appSemiBold
    }
})