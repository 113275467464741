import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  Platform,
} from "react-native";
import Base from "../../base";

export default function Loadingview(props) {
  const colour = Platform.OS === "web" ? Base.colours.appBlue : "white";
  return (
    <View style={styles.main}>
      <ActivityIndicator size="large" color={colour} />
      <Text style={[styles.title, { color: colour }]}>{props.title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    margin: 20,
    fontFamily: Base.fonts.appBold,
    fontSize: 16,
    textAlign: "center",
    color: "white",
  },
});
