import React, { useState, useRef } from 'react';
import { StyleSheet, TextInput, View, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import Base from '../../base';

export default function LoginTextField(props) {
	const [secure, setSecure] = useState(props.secure ?? false);
	const textfield = useRef()

	function secureToggled() {
		setSecure(!secure);
	}

	return (
		<View style={[styles.textField, styles.main]}>
			<Text style={styles.title}>{props.data.title}</Text>
			<View
				style={[styles.tfContainer, props.data.error && styles.error, props.height && { height: props.height }]}>
				{props.leftView && <TouchableOpacity style={[styles.accessoryView, { marginRight: 4 }]} onPress={props.onLeftViewTouch && props.onLeftViewTouch}>
					{props.leftView}
				</TouchableOpacity>}
				<View style={{ flex: 1, marginRight: 8 }}>
					<TextInput
						ref={textfield}
						style={[styles.textfield, props.style]}
						value={props.data.value}
						returnKeyType={props.data.returnKey ?? 'go'}
						autoCapitalize={'none'}
						selectionColor={Base.colours.appBlue}
						autoCompleteType={'off'}
						keyboardType={props.data.type ?? 'default'}
						placeholder={props.data.placeholder}
						onSubmitEditing={props.onSubmit && props.onSubmit}
						onChangeText={(text) => { props.onChange && props.onChange(props.index ?? 0, text) }}
						onKeyPress={({ nativeEvent }) => { props.onKeyPress && props.onKeyPress(props.index ?? 0, nativeEvent.key) }}
						secureTextEntry={secure}
						onFocus={() => { props.onFocus && props.onFocus(props.index ?? 0) }}
						placeholderTextColor={Base.colours.appSemiLightGrey}>
					</TextInput>
				</View>
				{props.secure &&
					<TouchableOpacity onPress={secureToggled}>
						<Icon name={secure ? 'eye-off' : 'eye'} size={22} color={Base.colours.appDarkBlue} />
					</TouchableOpacity>
				}
				{props.rightView && <TouchableOpacity style={styles.accessoryView} onPress={props.onRightViewTouch && props.onRightViewTouch}>
					{props.rightView}
				</TouchableOpacity>}
			</View>
			<Text style={[styles.errorTitle, !props.data.error && { color: 'white' }]}>{props.data.error ?? props.data.subtitle}</Text>
		</View>
	);
}


const styles = StyleSheet.create({
	main: {
		backgroundColor: 'transparent',
		marginBottom: 8
	},

	title: {
		height: 29,
		fontFamily: Base.fonts.appRegular,
		fontSize: 16,
		color: Base.colours.appBlack,
	},

	accessoryView: {
		height: '100%',
		justifyContent: 'center'
	},

	tfContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: 48,
		borderRadius: 24,
		borderColor: Base.colours.appSemiLightGrey,
		borderWidth: 1,
		paddingHorizontal: 16,
		alignItems: 'center',
		backgroundColor: 'white'
	},

	error: {
		borderWidth: 2,
		borderColor: Base.colours.appRed
	},

	errorTitle: {
		fontFamily: Base.fonts.appRegular,
		fontSize: 13,
		color: Base.colours.appRed,
		marginLeft: 16,
		marginTop: 1,
		height: 20
	},

	textfield: {
		fontSize: 16,
		height: 40,
		fontFamily: Base.fonts.appRegular,
		color: Base.colours.appDarkBlue,
		backgroundColor: 'white',
	}
})