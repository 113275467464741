import "intl";
import "intl/locale-data/jsonp/en";
import "react-native-gesture-handler";
import "react-native-match-media-polyfill";
import React from "react";
import { Platform } from "react-native";
import { useFonts } from "@use-expo/font";
import { createStackNavigator } from "@react-navigation/stack";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import FlashMessage from "react-native-flash-message";
import Base, { Store } from "./base";
import NavigationContainer from "./views/common/NavigationContainer";
import { SafeAreaProvider } from "react-native-safe-area-context";

const title = (text) =>
  Platform.select({ web: `Orderbee Dashboard ${text}`, default: text });

// Needed to prevent text cut-off on a lot of android devices
Base.functions.androidFontFix();

let persistor = persistStore(Store);
// Uncomment below to clear app storage on every launch
// persistor.purge();

const RootStack = createStackNavigator();

export default function App() {
  useFonts({
    "OpenSans-Light": require("./assets/fonts/OpenSans-Light.ttf"),
    "OpenSans-Regular": require("./assets/fonts/OpenSans-Regular.ttf"),
    "OpenSans-Bold": require("./assets/fonts/OpenSans-Bold.ttf"),
    "OpenSans-SemiBold": require("./assets/fonts/OpenSans-SemiBold.ttf"),
    "OpenSans-ExtraBold": require("./assets/fonts/OpenSans-ExtraBold.ttf"),
  });

  const linking = {
    prefixes: [],
    config: {
      screens: {
        Root: "",
        Settings: "settings",
        WaitTime: "wait-time",
        EndTime: "end-time",
      },
    },
  };

  return (
    <SafeAreaProvider>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <NavigationContainer linking={linking} title={title} Base={Base} />
          <FlashMessage position="top" />
        </PersistGate>
      </Provider>
    </SafeAreaProvider>
  );
}
