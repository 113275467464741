import React, { useState, useRef } from "react";
import { Text, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Animatable from "react-native-animatable";
import Base, { Auth, Actions, Firebase } from "../../base";
import { AppButton, Alert } from "../common";
import LoginTextField from "./LoginTextField";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function LoginScreen(props) {
  const defaultFields = {
    username: { title: "Username or Email", placeholder: "Username/email" },
    password: { title: "Password", placeholder: "Password" },
  };
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const isMobile = useMediaQuery(Base.constants.mobile);
  const dispatch = useDispatch();
  var view = useRef();

  props.navigation.setOptions(Base.constants.headerOptions(true));

  function saveCredentials(creds) {
    view.current
      .animate({ 0: { opacity: 1 }, 1: { opacity: 0 } }, 200)
      .then((endState) => {
        dispatch(Actions.setCredentials(creds));
      });
  }

  function validate() {
    let valid = true;
    let newFields = { ...fields };

    const username = newFields.username.value;
    if (!username || username.length === 0) {
      newFields.username.error = "Username/email is missing";
      valid = false;
    }

    const password = newFields.password.value;
    if (!password || password.length === 0) {
      newFields.password.error = "Password is missing";
      valid = false;
    }
    setFields(newFields);
    return valid;
  }

  async function loginTouched() {
    if (!validate()) {
      return;
    }
    setLoading(true);

    const creds = await Auth.login(
      fields.username.value,
      fields.password.value
    );
    if (creds) {
      Firebase.logEvent("login");
      saveCredentials(creds);
    } else {
      Alert.show(
        "Hold Up",
        "Unable to login with these details, please try again."
      );
      setLoading(false);
    }
  }

  function tfEdited(i, text) {
    let newFields = { ...fields };
    const keys = Object.keys(fields);
    let field = newFields[keys[i]];
    field.value = text;
    setFields(newFields);
  }

  function tfOnFocus(i) {
    let newFields = { ...fields };
    const keys = Object.keys(fields);
    let field = newFields[keys[i]];
    delete field.error;
    setFields(newFields);
  }

  async function forgotTouched() {
    const email = fields.username.value;
    if (!Base.functions.validateEmail(email)) {
      Alert.show(
        "Hold Up",
        "Please enter a valid email address to reset your password."
      );
      return;
    }
    let success = await Auth.forgotPassword(fields.username.value);
    const message = success
      ? `Success! If we have your address on file you'll receive an email with instructions to reset your password`
      : "Sorry we were unable to reset your password, please try again.";
    Alert.show("Reset Password", message);
  }

  return (
    <Animatable.View style={styles.main} ref={view}>
      <KeyboardAwareScrollView
        style={styles.scrollview}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={[styles.card, { opacity: loading ? 0.5 : 1.0 }]}
          pointerEvents={loading ? "none" : "auto"}
        >
          <Text style={isMobile ? Base.styles.title_sm : Base.styles.title_md}>
            Log In
          </Text>
          <Text style={[Base.styles.body, styles.subTitle]}>
            Manage your orders, stock levels and more
          </Text>
          <View
            style={{ opacity: loading ? 0.5 : 1.0 }}
            pointerEvents={loading ? "none" : "auto"}
          >
            <LoginTextField
              index={0}
              data={fields.username}
              onChange={tfEdited}
              onFocus={tfOnFocus}
            />
            <LoginTextField
              index={1}
              data={fields.password}
              onChange={tfEdited}
              onFocus={tfOnFocus}
              secure={true}
              onSubmit={loginTouched}
            />
            <AppButton
              style={styles.button}
              title=" Log in "
              onPress={loginTouched}
              loading={loading}
            />
            <TouchableOpacity
              style={styles.forgotButton}
              onPress={forgotTouched}
            >
              <Text style={styles.forgotTitle}>Forgot Password?</Text>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </Animatable.View>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Base.colours.appLightGrey,
  },

  card: {
    marginHorizontal: 16,
    maxWidth: 340,
  },

  subTitle: {
    marginTop: 15,
    marginBottom: 25,
  },

  button: {
    alignSelf: "center",
    marginBottom: 30,
    marginTop: 5,
    width: 240,
  },

  scrollview: {
    flex: 1,
    minWidth: "100%",
  },

  forgotButton: {
    width: "100%",
  },

  forgotTitle: {
    textAlign: "center",
  },
});
