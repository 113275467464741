import React from "react";
import { TouchableOpacity, Text, StyleSheet, View } from "react-native";
import Base from "../../base";

export default function Tabs(props) {
  const { tabs, selectedTab, onPress } = props;

  return (
    <View style={styles.main}>
      {Array.isArray(tabs) &&
        tabs.map((tab, index) => {
          return (
            <View style={styles.tab} key={index}>
              <TouchableOpacity
                onPress={() => props.onPress(index)}
                style={styles.tabNameWrapper}
              >
                <Text
                  style={[
                    styles.tabName,
                    selectedTab === index ? styles.selectedTabName : null,
                  ]}
                >
                  {tab.label}
                </Text>
                {tab.number > 0 && (
                  <View style={styles.tabNumberWrapper}>
                    <Text style={styles.tabNumber}>{tab.number}</Text>
                  </View>
                )}
              </TouchableOpacity>
            </View>
          );
        })}
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    height: 56,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Base.colours.appWhite,
    display: "flex",
    flexDirection: "row",
  },
  tabName: {
    color: Base.colours.appSemiLightGrey,
    paddingBottom: 3,
    borderBottomWidth: 2,
    borderBottomColor: "transparent",
    fontWeight: "bold",
    fontSize: 16,
  },
  selectedTabName: {
    color: Base.colours.appRed,
    borderBottomColor: Base.colours.appRed,
  },
  tabNameWrapper: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    position: "relative",
  },
  tab: {
    padding: 0,
  },
  tabNumberWrapper: {
    width: 14,
    height: 14,

    borderRadius: 7,
    backgroundColor: Base.colours.appRed,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    right: -2,
  },
  tabNumber: {
    fontSize: 10,
    textAlign: "center",
    // backgroundColor: Base.colours.appRed,
    // borderRadius: 13,
    // width: 13,
    // height: 13,
    color: Base.colours.appWhite,
    fontWeight: "bold",
    // position: "absolute",
    // top: 0,
    // right: 0,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
});
