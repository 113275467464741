import React from "react";
import { Dimensions, StyleSheet } from "react-native";
import HeaderLogo from "../views/common/HeaderLogo";
import HeaderTitle from "../views/common/HeaderTitle";

const hmargin = {
  sm: 12,
  md: 35,
  lg: 72,
};

const vmargin = {
  sm: 24,
  md: 40,
  lg: 40,
};

const inmargin = {
  sm: 16,
  md: 40,
  lg: 40,
};

export const colours = {
  appBlue: "#0899aa",
  appDarkBlue: "#084c61",
  appRed: "#d9594c",
  appLightRed: "#d9594c10",
  appGreen: "#419d78",
  appAlertGreen: "#46A881",
  appYellow: "#edae49",
  appDarkYellow: "#62471D",
  appOrange: "#f9a03f",
  appDarkGrey: "#2b2929",
  appNewDarkGrey: "#56666B",
  appBlueGrey: "#7f96a212",
  appGrey: "#56666b",
  appSemiLightGrey: "#d8d8d8",
  appLightGrey: "#f3f3f3",
  appVeryLightGrey: "#eeeeee",
  appBlack: "#1e1e24",
  appWhite: "#fff",
};

export const fonts = {
  appLight: "OpenSans-Light",
  appRegular: "OpenSans-Regular",
  appBold: "OpenSans-Bold",
  appSemiBold: "OpenSans-SemiBold",
  appExtraBold: "OpenSans-ExtraBold",
};

const styles = StyleSheet.create({
  title_sm: {
    fontSize: 24,
    color: colours.appDarkBlue,
    fontFamily: fonts.appExtraBold,
  },
  title_md: {
    fontSize: 37,
    color: colours.appDarkBlue,
    fontFamily: fonts.appExtraBold,
  },

  body: {
    fontFamily: fonts.appRegular,
    color: colours.appBlack,
    fontSize: 16,
  },

  headerButton: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },

  switch: {
    color: colours.appGreen,
  },
  shadowCard: {
    backgroundColor: "white",
    flex: 1,
    borderRadius: 4,
    elevation: 2,
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
});

function headerOptions(landing = false, title = "") {
  return {
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: colours.appYellow,
    },
    headerTintColor: "white",
    headerTitle: (props) => <HeaderTitle {...props} title={title} />,
    headerTitleStyle: {
      fontFamily: fonts.appExtraBold,
      fontSize: 24,
      fontWeight: "800",
    },
    headerRight: null,
    headerLeft: () => <HeaderLogo landing={landing} />,
  };
}

export default {
  window: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
  mobile: { maxWidth: 740 },
  tablet: { minWidth: 768, maxWidth: 1023 },
  hmargin,
  vmargin,
  inmargin,
  colours,
  fonts,
  headerOptions,
  styles,
};
