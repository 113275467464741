import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Platform,
  Button,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import Icon from "react-native-vector-icons/AntDesign";
import Base, { Api, Actions } from "../../../base";
import { Alert, AppButton } from "../../common";
import moment from "moment";
import DateTimePicker from "@react-native-community/datetimepicker";

function header(navigation, timeChanged, venue) {
  return {
    ...Base.constants.headerOptions(false, "End Time"),
    headerLeft: () => (
      <TouchableOpacity
        style={Base.styles.headerButton}
        onPress={() => {
          if (timeChanged) {
            Alert.showTwoButton(
              "Hold Up!",
              `You've not saved your new end time. Discard and return to settings?`,
              { text: "Discard Changes", onPress: () => navigation.goBack() },
              { text: "Continue Edit", onPress: () => {} }
            );
          } else {
            navigation.goBack();
          }
        }}
      >
        <Icon name="arrowleft" size={24} color="white" />
      </TouchableOpacity>
    ),
    headerTitleStyle: {
      fontFamily: Base.fonts.appExtraBold,
      fontSize: 18,
      fontWeight: "800",
    },
  };
}

export default function EndTimeScreen(props) {
  const isMobile = useMediaQuery(Base.constants.mobile);
  const [loading, setLoading] = useState(false);
  const venue = useSelector((state) => state.venue);
  const endTime = venue?.orderingUntil?.activeUntil;
  const friendlyTime = (time) => moment(time).format("YYYY-MM-DDTkk:mm");
  const userFriendlyEndTime = moment(newEndTime).format("hh:mm DD MMM YYYY");
  const [visible, setVisible] = useState(Platform.OS === "ios");
  const endTimeDate = new Date();
  const [newEndTime, setNewEndTime] = useState(endTimeDate);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [androidPickerState, setAndroidPickerState] = useState("date");
  useEffect(() => {
    const timePayload = Platform.OS === "web" ? friendlyTime(endTime) : endTime;
    setNewEndTime(timePayload);
  }, []);

  const dispatch = useDispatch();
  props.navigation.setOptions(header(props.navigation, timeChanged, venue));

  const timeChanged = friendlyTime(endTime) !== newEndTime;
  const setIosNewEndTime = (e, date) => {
    if (date === undefined) {
      setShowDateTimePicker(false);
      return;
    }
    const formattedDate = new Date(date).toISOString();
    setNewEndTime(formattedDate);
    setShowDateTimePicker(false);
  };
  const setAndroidNewEndDate = async (e, date) => {
    await setShowDateTimePicker(false);
    if (date === undefined) {
      await setShowDateTimePicker(false);
      return;
    } else {
      const formattedDate = await new Date(date).toISOString();
      await setNewEndTime(formattedDate);
      if (androidPickerState === "date") {
        await setAndroidPickerState("time");
        await setShowDateTimePicker(true);
        return;
      } else {
        await setAndroidPickerState("date");
        await setShowDateTimePicker(false);
      }
    }
  };
  const onSave = async () => {
    try {
      await setLoading("Updating service");
      const formattedEndTime = new Date(newEndTime).toISOString();
      console.log({ formattedEndTime });
      const apiResponse = await Api.setEndTime({ time: formattedEndTime });
      console.log({ apiResponse });
      const apiResponseTime = apiResponse?.activeUntil;
      if (apiResponseTime) {
        await dispatch(Actions.setEndTime(apiResponse));
        const timePayload =
          Platform.OS === "web"
            ? friendlyTime(apiResponse?.activeUntil)
            : apiResponse?.activeUntil;
        setNewEndTime(timePayload);
        setLoading(null);
      } else {
        await setLoading(null);
        throw new Error("API response invalid");
      }
    } catch (error) {
      console.error("An error occurred setting service state", error);
    }
  };
  return (
    <View style={styles.main}>
      <View style={[styles.content, !isMobile && { marginTop: 50 }]}>
        <Text
          style={[Base.styles.body, { textAlign: "center", marginBottom: 39 }]}
        >
          You should set the time that you will end your shift, in order to
          disable ordering.
        </Text>
        <TouchableOpacity
          style={styles.textfield}
          activeOpacity={1}
          onPress={() => setVisible(!visible)}
        >
          <View style={{ marginBottom: 0 }}>
            {Platform.OS === "web" ? (
              <input
                type="datetime-local"
                required
                value={newEndTime}
                onChange={(e) => setNewEndTime(e.target.value)}
                style={{
                  borderWidth: 0,
                  borderRadius: 54,
                  backgroundColor: Base.colours.appWhite,
                  padding: 12,
                  minWidth: 200,
                  textAlign: "center",
                  marginBottom: 0,
                }}
              />
            ) : (
              <View style={{ width: "100%" }}>
                <View style={styles.buttonWrapper}>
                  <TouchableOpacity
                    activeOpacity={1}
                    onPress={(e) => setShowDateTimePicker(!showDateTimePicker)}
                  >
                    <Text>{moment(newEndTime).format("DD MMM, HH:mm")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        </TouchableOpacity>
        <AppButton
          style={styles.saveButton}
          title="Save"
          onPress={onSave}
          loading={loading}
          disabled={!timeChanged}
        />
      </View>
      {Platform.OS === "ios" && showDateTimePicker && (
        <DateTimePicker
          style={styles.pickeriOS}
          testID="dateTimePicker"
          value={new Date(newEndTime)}
          mode="datetime"
          is24Hour={true}
          display="spinner"
          onChange={(e, date) => setNewEndTime(date)}
        />
      )}
      {Platform.OS === "android" && showDateTimePicker && (
        <DateTimePicker
          style={styles.pickerAndroid}
          testID="dateTimePicker"
          value={new Date(newEndTime)}
          mode={androidPickerState}
          minimumDate={new Date()}
          is24Hour={true}
          display="default"
          onChange={(e, date) => setAndroidNewEndDate(e, date)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: Base.colours.appLightGrey,
    alignItems: "center",
  },

  content: {
    maxWidth: 600,
    marginHorizontal: 16,
    height: 500,
    marginTop: 25,
    display: "flex",
    position: "relative",
  },

  subtitle: {
    marginTop: 35,
    fontFamily: Base.fonts.appSemiBold,
    fontSize: 16,
    textAlign: "center",
  },

  textfield: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
    marginBottom: 0,
  },

  timeInputText: {
    textAlign: "center",
    fontFamily: Base.fonts.appSemiBold,
    fontSize: 16,
  },

  timeSuffix: {
    fontFamily: Base.fonts.appSemiBold,
    fontSize: 16,
    marginLeft: 6,
  },

  saveButton: {
    alignSelf: "center",
    width: 200,
    height: 44,
    marginTop: 40,
  },

  pickeriOS: {
    height: "40%",
    width: "100%",
    position: "absolute",
    bottom: 0,
    backgroundColor: "white",
  },

  pickerAndroid: {
    marginLeft: 10,
    marginRight: 10,
    height: "40%",
    width: "100%",
    position: "absolute",
    bottom: 0,
    backgroundColor: "white",
  },

  saveButtonWrapper: {
    marginTop: 39,
    width: 200,
  },
  buttonWrapper: {
    backgroundColor: Base.colours.appWhite,
    paddingHorizontal: 16,
    borderRadius: 54,
    height: 49,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
