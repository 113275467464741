import { Audio } from 'expo-av';

async function playAlert() {

    const soundObject = new Audio.Sound();
    try {
      await soundObject.loadAsync(require('../../assets/alert.mp3'));
      await soundObject.playAsync();
    } catch (error) {
      console.log('unable to play sound: ', error);
    }
}

export default { playAlert }