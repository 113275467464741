import React from "react";
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  View,
  Platform,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import Base from "../../../base";
import * as Animatable from "react-native-animatable";
import moment from "moment";
import EmptyOrderListItem from "./EmptyOrderListItem";
import { AppButton } from "../../common";
import { useSelector } from "react-redux";

export default function OrdersListItem(props) {
  const { user, order, delay, onMoreInfo, onPress } = props;
  const permissions = user?.permissions;
  const me = useSelector((state) => state.me);
  const refundReasons = me?.business?.orderCancellationReasons;
  const canRefund =
    permissions?.includes("update:order-status") &&
    refundReasons?.length &&
    props?.order?.status !== "completed" &&
    props.order?.status !== "cancelled";
  if (order.empty) {
    return <EmptyOrderListItem delay={delay ?? 0} />;
  }
  const opacity = !moment(order.orderedAt).isSame(moment(), "day") ? 0.4 : 1.0;
  const timeColour =
    order.type.toLowerCase() === "oat"
      ? Base.colours.appGreen
      : Base.colours.appDarkBlue;
  const title =
    order.type.toLowerCase() === "oat" || order.type.toLowerCase() === "poat"
      ? "Table"
      : "Collect";
  const ctaTitle =
    order.status === "ordered"
      ? "ready"
      : order.status === "ready"
      ? "completed"
      : order.status === "loading"
      ? "loading"
      : "error";
  let time;
  if (order?.timeslot) {
    time = moment(order.timeslot).format("HH:mm");
  }
  const subtitle =
    order.type.toLowerCase() === "oat" || order.type.toLowerCase() === "poat"
      ? `#${order.destination ?? "NA"}`
      : time;

  const handlePress = () => {
    if (order.status !== "loading" || order.status !== "error") {
      onPress({ order, status: ctaTitle });
    }
  };
  return (
    <Animatable.View
      style={[styles.main, Platform.OS === "web" && { width: "100%" }]}
      animation={"fadeIn"}
      delay={delay ?? 0}
      duration={200}
    >
      <View style={styles.cardShadow}>
        <View style={styles.card}>
          <View
            style={[
              styles.time,
              { backgroundColor: timeColour, opacity: opacity },
            ]}
          >
            <Text style={styles.timeTitle}>{title}</Text>
            <Text style={styles.timeText}>{subtitle}</Text>
            {order.type.toLowerCase() === "poat" ? (
              <Text style={styles.timeTitle}>at {time || "N/A"}</Text>
            ) : null}
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.infoContainer}>
              <View style={{ flex: 1 }}>
                <Text style={styles.title}>{order.customer.firstName}</Text>
                <Text style={[Base.styles.body, styles.subtitle]}>
                  {order.quantity} {order.quantity === 1 ? "Item" : "Items"}
                </Text>
                <Text style={[Base.styles.body, styles.subtitle]}>
                  Submitted {moment(order.orderedAt).format("HH:mm")}
                </Text>
                <Text style={[Base.styles.body, styles.subtitle]}>
                  Current status: {order.status}
                </Text>
              </View>
              <TouchableOpacity
                style={styles.icon}
                onPress={() => onMoreInfo(order)}
              >
                <Icon
                  name="chevron-right"
                  size={30}
                  color={Base.colours.appRed}
                />
              </TouchableOpacity>
            </View>
            {user?.userVersion === 2 &&
              permissions?.includes("update:order-status") && (
                <View style={styles.ctaWrapper}>
                  {canRefund ? (
                    <AppButton
                      title="Reject"
                      onPress={() =>
                        onPress({ order: props.order, status: "cancelled" })
                      }
                      style={{ marginRight: 12, flexGrow: 1 }}
                      negative={true}
                    />
                  ) : null}
                  {order?.status !== "completed" &&
                    order?.status !== "cancelled" && (
                      <AppButton
                        title={ctaTitle}
                        onPress={handlePress}
                        loading={order.isLoading}
                        style={{ flexGrow: 1, textTransform: "capitalize" }}
                      />
                    )}
                </View>
              )}
          </View>
          <Text style={styles.id}>#{props.order.reference}</Text>
        </View>
      </View>
    </Animatable.View>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    marginVertical: 5,
    maxWidth: 400,
    alignSelf: "center",
    flexDirection: "row",
  },
  cardShadow: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 4,
    shadowOpacity: 0.15,
    elevation: 2,
    shadowOffset: {
      width: 1,
      height: 1,
    },
  },
  card: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "white",
    overflow: "hidden",
    borderRadius: 1,
    borderRadius: 4,
  },
  time: {
    width: 93,
    alignItems: "center",
    justifyContent: "center",
  },

  timeTitle: {
    color: "white",
    fontFamily: Base.fonts.appExtraBold,
    fontSize: 18,
  },

  timeText: {
    color: "white",
    fontFamily: Base.fonts.appExtraBold,
    fontSize: 24,
  },

  infoContainer: {
    flex: 1,
    flexDirection: "row",
    padding: 12,
  },

  title: {
    flex: 1,
    fontSize: 16,
    color: Base.colours.appDarkBlue,
    fontFamily: Base.fonts.appExtraBold,
  },

  subtitle: {
    marginVertical: 2,
  },

  icon: {
    alignSelf: "center",
    marginHorizontal: 7,
  },

  id: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    position: "absolute",
    color: Base.colours.appDarkBlue,
    right: 4,
    top: 0,
    margin: 4,
  },
  ctaWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    paddingTop: 0,
    width: "100%",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "flex-end",
  },
});
