import React from 'react';
import { Linking, Text, Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive'
import { showMessage } from "react-native-flash-message";
import DeviceInfo from 'react-native-device-info';
import moment from 'moment';
import Alert from '../views/common/Alert';
import Constants from './Constants';
import Api from '../modules/api';
import Sound from '../modules/audio';


const callNumber = function (number) {
	let tel = number.replace(/\s/g, '');
	Linking.openURL(`tel://${tel}`).catch(error => {
		Alert.show(
			'Support',
			`Your device does not support calling.\n\nPlease dial ${number} from another phone.`)
	})
}


const sendEmail = function (address) {
	Linking.openURL(`mailto://${address}`).catch(error => {
		Alert.show(
			'Support',
			`Your device has no email accounts set up.\n\nPlease add one and try again.`)
	})
}


const androidFontFix = () => {
	if (Platform.OS !== 'android') {
		return
	}

	const oldRender = Text.render
	Text.render = function (...args) {
		const origin = oldRender.call(this, ...args);
		return React.cloneElement(origin, {
			textBreakStrategy: 'simple'
		})
	}
}


function versionNumber() {
	if (Platform.OS === 'web') return 'Version w1.2.0 dev';
	const OS = Platform.OS === 'ios' ? 'P' : 'A';
	const OSVersion = Number(Platform.Version) ?? '0';
	const appVersion = DeviceInfo.getVersion() ?? '0';
	const buildVersion = DeviceInfo.getBuildNumber() ?? '0';
	return `Version ${OS}${OSVersion}-${appVersion}-${buildVersion}`;
}


export function Desktop({ children }) {
	const isDesktop = useMediaQuery({ minWidth: 992 })
	return isDesktop ? children : null
}
export function Tablet({ children }) {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
	return isTablet ? children : null
}
export function Mobile({ children }) {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	return isMobile ? children : null
}


function validateEmail(email) {
	if (!email) { return false; } 
	const re = /\S+@\S+\.\S+/;
		return re.test(email);
}


function showNewOrderMessage(order, onPress) {
	Sound.playAlert();
	const time = (order.type === 'OAT') ? order.createdAt : order.timeslot
	const timeString = moment(time).format('HH:mm');
	let message = (order.type != 'OAT') ? `Collection requested for ${timeString}. Tap for details.` : `New order received at ${timeString}. Tap for details.`

	showMessage({
		message: 'New Order!',
		description: message,
		type: "info",
		icon: 'auto',
		style: { paddingVertical: 40, },
		backgroundColor: Constants.colours.appAlertGreen,
		titleStyle: { fontFamily: Constants.fonts.appExtraBold, fontSize: 18, paddingTop: 3 },
		textStyle: { fontFamily: Constants.fonts.appSemiBold, fontSize: 16, marginRight: 15 },
		duration: 12000,
		onPress
	});
}


function showRejectedMessage(order) {
	let time = moment(order.timeslot || order.createdAt).format('HH:mm');
	Sound.playAlert();

	// TODO - change display if order is for a table
	Alert.show(
		'Pending Order Timeout!',
		`\nOrder number #${order.reference} for ${time} by ${order.customer.firstName} was automatically rejected due to timeout.
		\n\nPlease make sure to accept or reject new orders within 10 minutes.`);
}


async function logoutUser(completed = (() => { })) {
	return Alert.showTwoButton(
		'Sign Out',
		`\nAre you sure you want to sign out of Orderbee?`,
		{
			text: 'Sign Out', onPress: (async () => {
				let success = await Api.logout();
				completed(success);
			})
		},
		{ text: 'Cancel', onPress: (() => completed(false)) }
	)
}


function setNestedProperty(obj, path, value) {
	var schema = obj;  // a moving reference to internal objects within obj
	var pList = path.split('.');
	var len = pList.length;
	for (var i = 0; i < len - 1; i++) {
		var elem = pList[i];
		if (!schema[elem]) schema[elem] = {}
		schema = schema[elem];
	}

	schema[pList[len - 1]] = value;
	return obj;
}


export default {
	callNumber,
	sendEmail,
	androidFontFix,
	showNewOrderMessage,
	showRejectedMessage,
	logoutUser,
	versionNumber,
	setNestedProperty,
	validateEmail,
}