import React from 'react';
import { Image, View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';


export default function HeaderTitle(props) {
	const b = useSelector(state => state.business)
	const isMobile = useMediaQuery({ maxWidth: 767 });

	function brandImage() {
		let img = b && b.affiliation && b.affiliation.scheme && b.affiliation.scheme.admin && b.affiliation.scheme.admin.logo;
		if (!img || img === null) {
			img = b && b.branding && b.branding.admin && b.branding.admin.logo
		}
		return img;
	}
	return (
		<View style={styles.main}>
			<Text style={[styles.title, (!isMobile || !brandImage()) && {fontSize: 23}]} textBreakStrategy={'simple'}>{props.title}</Text>
			<View style={{width:5}}/>
			{(brandImage() && `${props.title}`.length > 0) && <Image style={[styles.brandImage, !isMobile && {width: 100}]} source={{uri:brandImage()}}/>}
		</View>
	);
}


const styles = StyleSheet.create({
	main: {
		flexDirection: 'row',
		marginBottom: 3,
		alignItems: 'center',
		justifyContent: 'center',
	},

	brandImage: {
		resizeMode: 'contain',
		width: 60,
		height: 60
	},

	title: {
		color: 'white',
		fontSize: 18,
		fontFamily: 'OpenSans-ExtraBold',
	}
})

