import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Platform,
} from "react-native";
import { Badge } from "react-native-elements";
import Base from "../../base";

export default function SettingsHeaderButton(props) {
  const titleStyle = props.selected ? styles.selectedTitle : styles.title;
  const containerStyle = [
    styles.container,
    {
      borderBottomColor: props.selected ? Base.colours.appRed : "transparent",
      marginHorizontal: props.isMobile ? 0 : 16,
    },
  ];

  return (
    <TouchableOpacity
      style={[
        styles.main,
        props.isMobile ? { marginHorizontal: 10 } : { height: 56 },
      ]}
      onPress={() => props.onChanged(props.service)}
    >
      <View style={containerStyle}>
        <View>
          <Text style={titleStyle}>{props.service.label}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  main: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },

  container: {
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 2.0,
    paddingBottom: 2,
  },

  title: {
    justifyContent: "center",
    fontFamily: Base.fonts.appExtraBold,
    color: Base.colours.appDarkBlue,
    opacity: 0.5,
    fontSize: 16,
  },

  selectedTitle: {
    justifyContent: "center",
    fontFamily: Base.fonts.appExtraBold,
    color: Base.colours.appRed,
    fontSize: 16,
  },
});
