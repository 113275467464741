import { useMemo } from "react";
import { useSelector } from "react-redux";
const jwtDecode = require("jwt-decode");

export const useUsername = () => {
  const user = useSelector((state) => state.credentials);
  const username = useMemo(() => {
    if (!user?.idToken) {
      return "";
    }

    const jwt = jwtDecode(user?.idToken ?? "");
    return jwt["email"]?.length > 0 ? jwt["email"] : jwt["nickname"];
  }, [user]);

  return username || 0
};
