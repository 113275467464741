import React from "react";
import { StyleSheet, Text } from "react-native";
import Base from "../../base";
import { useUsername } from "../../modules/hooks";

const HeaderUsername = ({}) => {
  const username = useUsername();
  
  return <Text style={styles.username}>{username}</Text>;
};

const styles = StyleSheet.create({
  username: {
    fontFamily: Base.fonts.appSemiBold,
    fontSize: 12,
    color: Base.colours.appDarkBlue,
    marginHorizontal: 1,
    letterSpacing: 0,
    flexShrink: 1,
  },
});

export default HeaderUsername;
