import React from "react";
import { View, FlatList, StyleSheet, Text } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import Base from "../../../base";
import OrdersListItem from "./OrdersListItem";

export default function OrdersList(props) {
  let title = props?.orders?.length === 0 ? "Awaiting Orders" : "Destination:";

  function renderItem(order, i) {
    return (
      <OrdersListItem
        delay={0}
        key={i}
        order={order.item}
        onMoreInfo={props.onMoreInfo}
        onPress={props.onPress}
        user={props.user}
      />
    );
  }

  return (
    <FlatList
      style={styles.main}
      refreshControl={
        <RefreshControl
          refreshing={props.isRefreshing}
          onRefresh={props.onRefresh}
        />
      }
      ListHeaderComponent={() => (
        <View style={styles.header}>
          <Text style={styles.title}>{title}</Text>
        </View>
      )}
      ListFooterComponent={() => <View style={styles.footer} />}
      showsVerticalScrollIndicator={false}
      data={
        props?.orders?.length > 0
          ? props.orders
          : [{ empty: true, reference: "1", id: `empty` }]
      }
      extraData={props.sortIndex}
      keyExtractor={(order, index) => order.id}
      renderItem={renderItem}
    />
  );
}

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 12,
    maxWidth: 400,
    alignSelf: "center",
    width: "100%",
  },

  title: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    marginBottom: 10,
    marginTop: 25,
  },

  footer: {
    height: 50,
  },

  header: {
    alignSelf: "center",
    width: "100%",
    maxWidth: 400,
  },
});
