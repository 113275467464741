import moment from "moment";
import axios from "axios";
import Firebase from "../firebase";
import Auth from "../auth";
import Sentry from "../sentry";
import Store from "../store";
import Actions from "../store/Actions";
import MQTT from "../mqtt";

const GET = "get";
const POST = "post";
const PATCH = "patch";
const DELETE = "delete";

async function performRequest(method, endpoint, body = null) {
  try {
    const baseUrl = await Firebase.getConfigValue("api_url");
    const url = (await baseUrl) + endpoint;
    const token = await Auth.getToken();
    if (!token) {
      logout();
      return;
    }

    var params = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    if (body !== null) {
      params.data = JSON.stringify(body);
    }
    if (method === "get") {
      delete params.data;
    }
    return axios(params)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        Sentry.logError(error);
        console.log(error);
        return null;
      });
  } catch (error) {}
}

async function getVenue() {
  const creds = Store.getState().credentials;
  let venue = await performRequest(GET, `/venues/${creds.venueId}`);
  if (!venue || !venue.uniqueRef) return {};
  let menus = await performRequest(
    GET,
    `/venues/${creds.venueId}/available-menus`
  );
  venue.menus = menus?.menus;
  return venue;
}

async function saveWaitTime(averageWaitTime) {
  const creds = Store.getState().credentials;
  return await performRequest(PATCH, `/venues/${creds.venueId}/services/oat`, {
    averageWaitTime,
  });
}

async function pauseMenu(key) {
  const creds = Store.getState().credentials;
  const body = {
    service: "oat",
    menu: key,
  };
  return await performRequest(POST, `/venues/${creds.venueId}/pause`, body);
}

async function unPauseMenu(key) {
  const creds = Store.getState().credentials;
  const body = {
    service: "oat",
    menu: key,
  };
  return await performRequest(DELETE, `/venues/${creds.venueId}/pause`, body);
}

async function getOrders() {
  const creds = Store.getState().credentials;
  const from = moment().utc().subtract(1, "day").format();
  const to = moment().utc().add(1, "day").format();
  const response =
    (await performRequest(
      GET,
      `/venues/${creds.venueId}/orders?from=${from}&to=${to}`
    )) || [];
  const orders = response.sort((a, b) =>
    moment.utc(b.createdAt).diff(a.createdAt)
  );
  return orders;
}

async function getOrder(orderId) {
  const creds = Store.getState().credentials;
  const order = await performRequest(
    GET,
    `/venues/${creds.venueId}/orders/${orderId}`
  );
  return order;
}

async function logout() {
  try {
    Store.dispatch(Actions.logout());
    MQTT.disconnect();
    return true;
  } catch (error) {
    console.log(error);
    Store.dispatch(Actions.logout());
    return false;
  }
}

const setVenueServiceState = async ({ service, state }) => {
  try {
    const creds = Store.getState().credentials;
    const serviceKey = `enable${service?.shortcode}`;
    const body = {
      [serviceKey]: state,
    };
    const res = await performRequest(
      PATCH,
      `/venues/${creds.venueId}/ordering-mods`,
      body
    );
    if (res?.orderingMods) {
      const newServicesStates = res?.orderingMods;
      return newServicesStates;
    }
    return false;
  } catch (error) {
    console.error("Error occurred setting the venue service state", error);
    return false;
  }
};

const getMe = async () => {
  const res = await performRequest(GET, `/me`, {});
  return res;
};

const setEndTime = async ({ time }) => {
  try {
    const creds = Store.getState().credentials;

    const body = {
      activeUntil: time,
    };
    const res = await performRequest(
      POST,
      `/venues/${creds.venueId}/ordering-until`,
      body
    );
    if (res?.orderingUntil) {
      const orderingUntil = res?.orderingUntil;
      return orderingUntil;
    }
    return false;
  } catch (error) {
    console.error("Error occurred setting the venue service state", error);
  }
};

const setOrderStatus = async ({ orderId, venueId, status, reason }) => {
  try {
    const creds = Store.getState().credentials;
    const body = {
      status,
      reason,
    };
    const res = await performRequest(
      PATCH,
      `/venues/${venueId}/orders/${orderId}/status`,
      body
    );
    if (res._id) {
      return res;
    }
    return false;
  } catch (error) {
    console.error("Error occurred setting the order status", error);
    return "error";
  }
};

export default {
  getVenue,
  saveWaitTime,
  pauseMenu,
  unPauseMenu,
  getMe,
  getOrders,
  getOrder,
  logout,
  setVenueServiceState,
  setEndTime,
  setOrderStatus,
};
