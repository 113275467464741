import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Text,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import Modal from "modal-react-native-web";
import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/AntDesign";
import Icon2 from "react-native-vector-icons/Feather";
import Base, { Api, Actions, Firebase } from "../../base";
import { Alert, LoadingView, ExpandableButton } from "../common";
import SettingsHeader from "./SettingsHeader";
import SettingsSwitch from "./SettingsSwitch";
import HeaderUsername from "../common/HeaderUsername";
import ScreenBanner from "../common/ScreenBanner";
import { useUsername } from "../../modules/hooks";

function settingsHeader(navigation, logout, isMobile) {
  return {
    ...Base.constants.headerOptions(false, "Settings"),
    headerLeft: () => (
      <TouchableOpacity
        style={Base.styles.headerButton}
        onPress={() => navigation.goBack()}
      >
        <Icon name="arrowleft" size={24} color="white" />
      </TouchableOpacity>
    ),
    headerRight: () => (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!isMobile && <HeaderUsername />}
        <TouchableOpacity style={Base.styles.headerButton} onPress={logout}>
          <Icon2 name="log-out" size={24} color="white" />
        </TouchableOpacity>
      </View>
    ),
  };
}

export default function SettingsScreen(props) {
  const services = [
    {
      label: "Takeaway",
      shortcode: "CC",
      menuName: "takeaway",
    },
    {
      label: "Order at Table",
      shortcode: "OAT",
      menuName: "oat",
    },
  ];
  const [service, setService] = useState(services[0]);
  const [loading, setLoading] = useState(null);
  const isMobile = useMediaQuery(Base.constants.mobile);
  const user = useSelector((state) => state.credentials);
  const venue = useSelector((state) => state.venue);
  const dispatch = useDispatch();
  async function logout() {
    await Base.functions.logoutUser(() => {
      Firebase.logEvent("logout_settings");
      props.navigation.push("Root");
    });
  }

  props.navigation.setOptions(
    settingsHeader(props.navigation, logout, isMobile)
  );
  useEffect(() => {
    if (!user) {
      props.navigation.push("Root");
    }
  }, [user]);
  async function menuToggled(key) {
    let enabled = menuPaused(key);

    setLoading("Updating Service...");
    let success = false;
    if (!enabled) {
      dispatch(Actions.pauseMenu(key));
      success = await Api.pauseMenu(key);
    } else {
      dispatch(Actions.unPauseMenu(key));
      success = await Api.unPauseMenu(key);
    }

    if (!success) {
      Alert.show(
        "Hold Up!",
        "Unable to update this service at the moment, please check your connection and try again."
      );
      dispatch(enabled ? Actions.pauseMenu(key) : Actions.unPauseMenu(key));
    }
    setLoading(null);
  }

  function menuPaused(key) {
    const pausedDate = venue?.oat?.menus?.[key]?.pausedUntil;
    if (!pausedDate) return false;
    return moment(pausedDate).isAfter(moment().utc());
  }

  const username = useUsername();

  return (
    <View style={styles.main}>
      {!!isMobile && <ScreenBanner text={username} />}
      <View style={{ flex: 1, flexDirection: !isMobile ? "row" : "column" }}>
        <SettingsHeader
          isMobile={isMobile}
          services={services}
          service={service}
          onServiceChanged={setService}
        />
        {service.shortcode === services[1].shortcode ? (
          <View style={styles.content}>
            <View style={{ maxWidth: 700, width: "100%" }}>
              {venue.menus &&
                venue.menus[service.menuName] &&
                venue.menus[service.menuName].map((menu, i) => {
                  return (
                    <SettingsSwitch
                      key={i}
                      isMobile={isMobile}
                      title={`Pause ${menu.title} Menu for 30 mins`}
                      enabled={menuPaused(menu.identifier)}
                      onValueChanged={() => menuToggled(menu.identifier)}
                    />
                  );
                })}
              <ExpandableButton
                style={styles.waitButton}
                title="Wait Time Message"
                onPress={() => props.navigation.push("WaitTime")}
                static={true}
              />
            </View>
          </View>
        ) : (
          <View style={styles.comingSoon}>
            <Text
              style={styles.comingSoonText}
            >{`${service.label} currently has no configurable settings, check back soon.`}</Text>
          </View>
        )}
      </View>
      {Platform.OS === "web" ? (
        <Modal animationType="fade" transparent={true} visible={!!loading}>
          <LoadingView title={loading} />
        </Modal>
      ) : (
        <Overlay
          isVisible={!!loading}
          windowBackgroundColor="#00000097"
          overlayStyle={{
            backgroundColor: "transparent",
            shadowOpacity: 0,
            elevation: 0,
          }}
          animationType={"fade"}
          statusBarTranslucent
        >
          <LoadingView title={loading} />
        </Overlay>
      )}
      {isMobile ? (
        <View style={styles.footer}>
          <Text style={styles.versionNumber}>
            {Base.functions.versionNumber()}
          </Text>
        </View>
      ) : (
        <Text style={styles.tabletVersionNumber}>
          {Base.functions.versionNumber()}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: Base.colours.appLightGrey,
  },

  content: {
    flex: 1,
    marginTop: 30,
    alignItems: "center",
  },

  waitButton: {
    marginHorizontal: 16,
  },

  footer: {
    paddingTop: 12,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },

  versionNumber: {
    textAlign: "center",
    fontSize: 12,
    color: Base.colours.appGrey,
  },

  tabletVersionNumber: {
    fontSize: 12,
    color: Base.colours.appGrey,
    position: "absolute",
    right: 16,
    bottom: 16,
  },

  comingSoon: {
    marginHorizontal: 16,
    maxWidth: "100%",
    alignItems: "center",
    flex: 1,
  },

  comingSoonText: {
    fontFamily: Base.fonts.appBold,
    fontSize: 16,
    color: Base.colours.appGrey,
    textAlign: "center",
    marginTop: 40,
  },
});
