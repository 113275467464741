import React from "react";
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import Base from "../../base";

export default function AppButton(props) {
  const style = props.negative ? styles.negative : styles.positive;
  let body = props.icon ? (
    props.icon
  ) : (
    <Text
      style={[
        styles.title,
        { color: props.negative ? Base.colours.appDarkBlue : "white" },
      ]}
      allowFontScaling
    >
      {props.title}
    </Text>
  );
  if (props.loading) {
    body = (
      <ActivityIndicator
        size="small"
        color={props.negative ? Base.colours.appGrey : "white"}
      />
    );
  }

  return (
    <TouchableOpacity
      style={[styles.main, style, props.style]}
      onPress={props.onPress}
    >
      {body}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  main: {
    height: 48,
    borderRadius: 24,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },

  positive: {
    backgroundColor: Base.colours.appRed,
    borderWidth: 0,
  },

  negative: {
    borderWidth: 2,
    borderColor: Base.colours.appRed,
    backgroundColor: "white",
    shadowOpacity: 0,
  },

  title: {
    fontFamily: Base.fonts.appExtraBold,
    color: "white",
    fontSize: 17,
    textTransform: "capitalize",
  },
});
